//fuentes
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');
@mixin font-style($familia:$montserrat,$weight:400,$size:15px,$color:$grey1){
  font-family: $familia;
  font-weight: $weight;
  font-size: $size;
  color: $color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
}
//fin fuentes
.transition1{
  transition: all .25s ease-in-out;
}
*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
}
body{
  width: 100%;
  border: 0;
  max-width: 100%;
  padding: 0;
  margin: 0;
  //padding-top: 90px;
  @include respond-to(1024px){
    padding-top: 60px;
  }
  @include respond-to(780px){
    max-width: 100vw;
    overflow-x: hidden;
  }
  min-width:100%!important;
}
button,a,input,select,option{
  outline: 0;
  box-shadow: none;
  &:focus{
    box-shadow: none !important;
  }
}
.contentBTN{
  margin: 30px 0;
}

h1,h2,h3,h4,h5,h6,p,a,span{
  @include font-style($montserrat,500,15.5px,#fff);
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
  line-height: 1.5;
}
h1,h2,h3,h4,h5,h6{
  margin-bottom: 10px;
}
.btnRed,.btnWhite,.btnPurple,.btnLightPurple,.btnYellow{
  text-align: center;
  @extend .transition1;
  display: inline-block;
  padding: 1rem 1.5rem;
  box-shadow: 1px 2px 3px rgba($grey1, .5);
  @include font-style($montserrat, 600, 13.5px, #fff);
  &:hover {
    box-shadow: 1px 2px 3px rgba($grey1, .75);
    text-decoration: none;
  }
}
.btnYellow{
  background-color: $yellow1;
  &:hover {
    background-color: $yellow2;
    color:#fff;
  }
}
.btnPurple{
  background-color: $darkPurple1;
  &:hover {
    background-color: $darkPurple2;
    color:#fff;
  }
}
.btnLightPurple{
  background-color: $purple1;
  &:hover {
    background-color: $purple2;
    color:#fff;
  }
}
.btnWhite{
  color:$darkPurple1;
  border-radius: 4px;
  background-color: #ededed;
  &:hover {
    background-color: #fff;
    color:$darkPurple1;
  }
}
.btnRed{
  background-color: $red1;
  border-radius: 4px;
  &:hover {
    background-color: $red2;
    color:#fff;
  }
}
.link,.download{
  padding-left: 15px;
  position: relative;
  font-weight: 600;
  color:$purple1;
  @extend .transition1;
  &:hover{
    text-decoration: none;
    color:$purple1;
  }
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    width: 10px;
  }
}
.link{
  &:before{
    background:  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjVweCIgdmlld0JveD0iMCAwIDcgNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPmljb25fc2VsZWN0LWFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9ImxheW91dC9iYXItcmlwbGV5IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI1Ny4wMDAwMDAsIC0xMy4wMDAwMDApIj4KICAgICAgICA8ZyBpZD0iaW5wdXQvc2VsZWN0LWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjU2LjAwMDAwMCwgMTMuMDAwMDAwKSIgZmlsbD0iIzg5NDU5MCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMuMTkyOTY4MSw1LjgzMzMzMzMzIEMyLjk5NjYwMjA0LDUuODMzMzMzMzMgMi44MDAwOTY5MSw1Ljc1Nzg1OTE5IDIuNjQ2NTY0MjQsNS42MDYwNDE3IEMyLjMyOTIwNzc2LDUuMjkxNjg2ODEgMi4zMTY1NTI0NCw0Ljc2ODcyNzc0IDIuNjE4MTk0MDgsNC40MzgwMDMyMSBMNC4zOTI0NDIwOSwyLjQ5NDIxNzk1IEwyLjYyMDAwMTk4LDAuNTYzOTA1MDQgQzIuMzE3MjQ3NzksMC4yMzQzMzk0MjEgMi4zMjgzNzMzNSwtMC4yODg2MTk2NDggMi42NDQ4OTU0MSwtMC42MDQxMzM0NTggQzIuOTYxNTU2NTQsLTAuOTE5NjQ3MjY3IDMuNDYzNDU4MTgsLTAuOTA3OTEzMjgyIDMuNzY2MjEyMzcsLTAuNTc4MjAyOCBMNi4wNjE1NTM2LDEuOTIxMTM1OTMgQzYuMzU0MTU1NzIsMi4yMzk4MzY3NSA2LjM1NDcxMiwyLjc0MTc5MDU0IDYuMDYzMzYxNTEsMy4wNjE1MDU0IEwzLjc2ODE1OTM0LDUuNTc2NDg5NDUgQzMuNjExOTg0MzUsNS43NDY5OTQzOCAzLjQwMjk2Mjk3LDUuODMzMzMzMzMgMy4xOTI5NjgxLDUuODMzMzMzMzMgWiIgaWQ9IlNoYXBlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjM0MDcyMCwgMi41MDAwMDApIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtNC4zNDA3MjAsIC0yLjUwMDAwMCkgIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') center center no-repeat;
    background-size: contain;
    transform: rotate(-90deg);
  }
}
.download{
  &:before{
    background: transparent url("/assets/img/svg/i-download.svg") no-repeat center center;
    background-size:  contain;
  }
}
.arrow-down{
  position: relative;
  display: inline-block;
  font-family: $montserrat;
  width: 100%;
  &:after{
    content: '';
    height: 10px;
    width: 10px;
    background: transparent  url(/assets/img/svg/i-down-menu.svg) center center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 40%;
    right: 25px;
  }
}

.arrow-right{
  position: relative;
  display: inline-block;
  font-family: $montserrat;
  width: 100%;
  &:after{
    content: '';
    height: 10px;
    width: 10px;
    background: transparent url(/assets/img/svg/i-down-menu.svg) no-repeat right center;
    background-size: contain;
    position: absolute;
    bottom: 40%;
    right: 25px;
  }
}


.t0{
  font-size: 100px;

  @include respond-to(767px){
    font-size: 50px;
  }
}

.light{
  font-weight: 400 !important;
}
.semiBold{
  font-weight: 600 !important;
}
.bold{
  font-weight: 700 !important;
}
.yellow{
  color: $yellow2 !important;
}
.white{
  color: $white !important;
}
.purple{
  color: $purple1 !important;
}
.purple3{
  color: $purple3 !important;
}

.black{
  color: #000000 !important;
}

.gris{
  color: #4c4c4c !important;
}

.purpleC{
  color: #5E388A !important;
}

.purpleDark{
  color: $darkPurple1 !important;
}
.blue1{
  color: $blue1 !important;
}
.gold{
  color: $gold !important;
}
.purpleDark3{
  color: $darkPurple3 !important;
}
.purple4{
  color: $purple4 !important;
}
.red{
  color: $red1 !important;
}
.grey{
  color:$grey1 !important;
}
.grey2{
  color:$grey2 !important;
}
.grey4{
  color:$grey4 !important;
}
.grey5{
  color:$grey5 !important;
}

.grey0{
  color:#FFFFFF !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  margin-top: 50px;
  margin-left: 35px;
}

.grey00{
  color:#FFFFFF !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  margin-top: 50px;
  margin-left: 35px;
}

.grey1{
  color:#4C4C4C !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  margin-left: 12%;
  margin-top: 80px;
}
.grey10{
  color:#FFFFFF !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  margin-left: 12%;
  margin-top: 80px;
}

.lightGrey3{
  color:$lightGrey3 !important;
}
.lightGrey4{
  color:$lightGrey4 !important;
}
.upCase{
  text-transform: uppercase;
}
.content-line{
  position: relative;
  &:after{
    content: '';
    height: 100%;
    width: 1px;
    background-color: $lightGrey2;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
  }
}

.box-pg{
  max-width: 48%;
}
.box-no-client{
  max-width: 30%;
}
.box-no-client_child{
  max-width: 30%;
  background: #FFFFFF;
    border: 1.0482px solid rgba(82, 49, 120,0.3);
    box-sizing: border-box;
    box-shadow: 0 8px 20px #f2f4f9;
    border-radius: 8px;
}
.seccion-nc{
  height: 105px;
}

.box-center-pg{
  max-width: 4%;
  width: -webkit-fill-available;
  width: -moz-available;

}

.imgBannerLateral{
  width: 100%;
  height: 100%;
}
.textAlign{
  text-align: center;
}

.t1{
  font-size: 30px;
}
.t2{
  font-size: 22px;
}
.t3{
  font-size: 18px;
}
.t4{
  font-size: 16px;
}
.t5{
  font-size: 32px;
}
.t7{
  font-size: 28px;
}
.purpleC2{
  color:#6B409C !important;
}

.t6{
  font-size: 16px;
}
.s1{
  font-size: 14px;
}
.s5{
  font-size: 18px;
}
.s6{
  font-size: 12px;
}
.s7{
  font-size: 13px;
}
.s8{
  font-size: 20px;
}
.s9{
  font-size: 18px;
}
.s2{
  font-size: 12.5px;
}
.s3{
  font-size: 11px;
}
.s4{
  font-size: 16px;
}
.t24{
  font-size: 24px;
}
.t8{
  font-size: 35px;
}
.t9{
  font-size: 24.5px;
}
.t10{
  font-size: 16px;
}
// Extra small devices (portrait phones, less than 576px)

@media (max-width: 575.98px) {

  .MargintextoCheckOMP0{
    margin-right: -40px;
  }

  #nroDocumentoCP {
    max-width: none;
    width: 100% !important;
  }
  #nroDocumentoCP2 {
    max-width: none;
    width: 100% !important;
  }
  #nroDocumento {
    max-width: none;
    width: 100% !important;
  }
  .itemVinhetaSegmentador {
    margin-left: -10px !important;
  }
  .fixPaddingLeftExperiencia{
    padding-left: 14px !important;
  }

  .logo-br{
    max-width: 180px;
    margin-left: 3px !important;

  }
  .btnVerReglamentoRPTGO {
    margin-left: 5% !important;
  }
  .fontQueSonRipleyPuntosGo {
    width: 97% !important;
  }
  .fontTodoPuedeCanjear{
    width: 97% !important;
    margin-top: 10px;
  }
  .fontTextoPromoHome {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .footerVerReglamento{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center;

  }
  .styleFooterLineOne{
    padding-left: 15%;
    padding-right: 15%;
  }
  .cont-footer-new0{
    height: 80px !important;
  }

  .cssNoConsult {
    width: 100% !important;
  }
  .divCentroFix{
    margin: auto;
    text-align: left;
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 20px;

  }
  .fontDescubre{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 138.97%;
  }
  .formConsultaCP{
    margin-left: 20px !important;
    width: 220px !important;
  }
  .responsiveBoxCaja {
    line-height: 17px !important;
    margin-left: 1px !important;
    margin-right: -5px !important;
  }
  .responsiveBoxCaja2 {
    margin-left: 1px !important;
  }
  .col-title-segmento{
    margin-left: 0px !important;
  }
  .col-box-segmento {
    margin-left: 0px !important;
    width: 245px;
    height: 123px;
    margin: auto !important;
    margin-top: 20px !important;
  }
  .btnConsultarPrimeCanjePromo {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    display: flex;
    width: 60% !important;
    height: 35px;

  }
  .subtitleBoxCanjePromo{
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 138.97% !important;
    margin-top: -5px;

  }
  .imgLogoCanjePromo {
    margin-top: -10px;
    margin-left: -2px;
    width: 125px;
  }
  .imgTarjetaCanjePromo {
    margin-top: -110px !important;
    margin-left: 142px !important;
  }
  .titleBoxCanjePromoPrice {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 138.97% !important;
    display: flex !important;
    align-items: center !important;
  }
  .boxCanjePromoContainer {
    margin-left: 0px !important;
  }
  .ptitleBoxCanjePromo{
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 138.97% !important;
    margin-top: -10px !important;
  }
  .boxCanjePromo {
    width: 335px !important;
    height: 216px !important;
    position: relative;
    left: 50%;
    margin-bottom: -9px ;
  }
  .containerImgTriplicaCanjePromo {
    width: 375px !important;
    height: 402px !important;
    position: relative;
    left: 50%;
    margin-left: -187.5px !important;
    margin-bottom: 90px !important;
    text-align: center;
  }
  .fixFilaContainer {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .boxContainerExpeSuper {
    width: 335px !important;
    height: 251px !important;
    position: relative;
    left: 50%;
    margin-left: -167.5px !important;
    //
  }

  .pagelinkItem {
    margin-right: 10px !important;
    // width: auto !important;
  }
  .mt-responsiveCP {
    margin-bottom: -8px !important;
  }
  .mt-responsiveCP2 {
    margin-bottom: -14px !important;
  }
  .boxContainerExpeSuperTop {
    width: 334px !important;
    height: 153px !important;
  }

  .boxContainerExpeSuperBottom{
    width: 334px !important;
    height: 98px !important;
  }
  .boxContainerPromoTriplica{
    width: 334px !important;
    height: 225px !important;
  }
  .boxContainerPromotionTriplicaBottom{
    width: 334px !important;
    height: 98px !important;
  }


  // inicio
  .boxContainerExpeSuper {
    width: 334px !important;
    height: 263px !important;
    position: relative;
    left: 50%;
    margin-left: -167px !important;
  }

  .boxContainerExpeSuperExperiencia {
    width: 333px !important;
    height: 263px !important;
  }

  .boxContainerExpeSuperTopExperiencia {
    width: 333px !important;
    height: 165px !important;

  }
  .boxContainerExpeSuperBottomExperiencia{
    width: 333px !important;
    height: 97px !important;
  }
  // fin


  .fixIzquierdaMargenCanjePromo {
    margin-left: -165px !important;
  }
  .fixDerechaMargenCanjePromo{
    margin-left: -165px !important;
  }


  .fixMargenResponsiveCP {
    margin-right: 0% !important;
  }
  .CondicionesdeBeneficiosReglamento{
    margin-left: 0px !important;
  }
  .t7movilR {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 28px !important;
    text-align: center !important;
  }
  .t10{
    font-size: 14px;
  }
  .t1{
    font-size: 28px;
  }
  .s6{
    font-size: 15.5px;
  }
  .t3{
    font-size: 16px;
  }
  .t5{
    font-size: 30px;
  }
  .t7{
    font-size: 26px;
  }
  .t24{
    font-size: 18px;
  }
  .s4{
    font-size: 15px;
  }
  .s7{
    font-size: 14px;
  }
  .s9{
    font-size: 15px;
  }
  .img-mobile{
    height: 40px;
  }
  .img-mobile_sin_tarjeta {
    height: 100%;
  }
  .fiximg-mobile_sin_tarjeta {
    height: 70%  !important;
  }
  .box-pg{
    max-width: 100%;
  }
  .box-no-client{
    max-width: 100%;

  }
  .fixMargin_rptogo {
    margin-left: -15px;
    margin-right: -15px;
  }
  .box-no-client_child{
    max-width: 100%;
    margin-left: 42px;
    margin-right: 42px;
  }
  .box-center-pg{
    width: 100%;
    height: 25px
  }
  .textAlign{
    text-align: left;
  }
   .textAlign{
    text-align: left;
  }


  .hr-rpg-grey{
    border-top: 1.5px solid #737373;
  }

  .hr-rpg-gold{
    border-top: 1.5px solid #F8C21C;
  }
  #listaItemTutorialRptoGo {
    margin-left: -35px !important;
    margin-right: 5% !important;
  }
  .divBannerIzquierdoOMP {
    padding-top: 0px !important;
    padding-left: 0px !important;
    // height: 299px !important;
  }
  .divBannerIzquierdoPRO {
    padding-top: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
  }

  .divBannerIzquierdoPromociones {
    padding-top: 26px !important;
    padding-left: 35px !important;
    height: 299px !important;
  }

  .divBannerCentroOMP {
    padding-top: 30px !important;
  }
  .imgItemInstruccionOMP {
    height: 18px;
    width: 22px;
    margin-left: -10px;
    margin-top: -35px;
  }
  .fixCustomImg {
    width: 28px !important;
  }
  .itemInstruccionOMP {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding-left: 0px !important;
    padding-right: 29px !important;
    width: 150px;
    margin-top: -4px !important;
  }
  .fondoimgMovilBannerLateralOMP {
    margin-top: -315px !important;
    margin-left: -40px !important;
  }
  .titleRegistroOMP {
    font-size: 24px !important;
    line-height: 24px !important;
  }
  .descripcionRegistroOMP {
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;;
    line-height: 24px !important;;
    text-align: center;
    margin-left: 8%;
    margin-right: 8%;
  }
  .formContaintOMP{
    width: 82% !important;
    margin-left: 9% !important;
    margin-right: 9% !important;
  }
  .textoCheckOMP {
    text-align: left;
  }
  .btnRegistroOMP{
    height: 45px !important;
    margin-bottom: 30px;
  }
  .divBannerCentroOMPConfirm {
    margin-top: 40px !important;
    margin-bottom: 60px !important;
  }
  .formConfirmContaintOMP {
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .mensaje-final-OMP {
    width: 100% !important;
    height: 100% !important;
  }
  .subtitleConfirmOMP{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 15px !important;
    line-height: 135% !important;
    margin-top: -7px;
  }
  .btnInicioOMP {
    line-height: 20px !important;
    height: 50px !important;
    width: 170px;
  }
  .parrofoConfirmOMP{
    font-size: 15px !important;
    line-height: 145% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-top: 0px !important;
  }
  .fixmargenResponsive{
    margin-top: -21px !important;
  }
  .tituloCategoria_rpgo{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 28px !important;
    text-align: center !important;
    margin-top: 10px !important;
  }
  .tituloPromociones_rpgo{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 28px !important;
    text-align: center !important;
    margin-top: -90px;
  }
  .divCategoriaLeft {
    margin-left: 0px !important;
    margin-top: -10px !important;
  }
  .divCategoriaRight {
    margin-left: 0px !important;
    margin-top: -30px !important;
  }
  .divItemCategoria{
    margin-bottom: -20px;
    max-width: 100% !important;
  }
  .divItemCategoriaDescripcion {
    max-width: 100% !important;
  }
  .btnConsultarPrime {
    line-height: 0.9;
    width: 100% !important;
    max-width:100% !important;
  }
  .titleBen_Categ {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 34px !important;
    text-align: center !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: 25px !important;
  }
  .subtitleBen_Categ{
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 28px !important;
    text-align: center !important;
  }
  .CondicionesdeBeneficios{
    text-decoration: underline;;
  }
  #appModalTerminosBeneficios {
    padding-right: 0px !important;
  }
  #appModalTerminosLegal {
    padding-right: 0px !important;
  }
  .fixwidthRespuestaPreguntaFrecuente{
    width: 100% !important;
  }
  .itemRespuestaPreguntaFrecuente{
    text-align: justify !important;
    margin-right: 10px;
  }
  .fixRespuestaPrestamo{
    padding-right: 130px;
  }
  .itemRequisitosCanjearPuntos{
    margin-left: 20px !important;
    margin-top: -23px !important;
  }
  .imgitemRequisitosCanjearPuntos{
    margin-left: -50px !important;
  }
  .fixPaddingLeftImgPreguntaFrecuentaCat{
    padding-left: 0px !important;
    margin-top: 5px !important;
    // margin-top: -4px !important;
  }
  .altoImgVitrinaCategoria{
    height: 364px !important;
  }
  .contenedorAunSinTarjeta{
    margin-top: -50px !important;
  }
  .fixResponsiveContentVitrina{
    margin-top: -120%;
    margin-left: 8%;
    width: 100%;
  }
  .titleVitrina{
    font-style: normal;
    font-weight: bold !important;
    // font-size: 30px !important;
    // line-height: 37px !important;
    width: 90% !important;
    // margin-top: -20px;

    line-height: 28px !important; 
    font-size: 20px !important; 
    white-space: pre-wrap;
    margin-top: -38%;
  }
  .sub-titleVitrina{
    width: 100% !important;
    padding-right: 50px;
    margin-top: -13px;
    margin-bottom: -13px;
    font-size: 14px !important;
  }
  .colorItemAcordionRptoGo{
    line-height: 20px !important;
  }
  .contenedorCategorias{
    margin-top: -60px !important;
  }
  .cont-descuentos_fix {
    margin-top: -80px !important;
  }
  .prev, .next {
    top: 40% !important;
  }

}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .tituloPromociones_rpgo{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 28px !important;
    text-align: center !important;
    margin-top: -90px;
  }

  .boxContainerExpeSuper {
    width: 334px !important;
    height: 263px !important;
    position: relative;
    left: 50%;
    margin-left: -167px !important;
  }
  .containerImgTriplicaCanjePromo {
    width: 375px !important;
    height: 402px !important;
    position: relative;
    left: 50%;
    margin-left: -187.5px !important;
    margin-bottom: 90px !important;
    text-align: center;
  }
  #nroDocumentoCP {
    max-width: none;
    width: 100% !important;
  }
  #nroDocumentoCP2 {
    max-width: none;
    width: 100% !important;
  }
  #nroDocumento {
    max-width: none;
    width: 100% !important;
  }
  .divBannerIzquierdoPRO {
    padding-top: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .itemVinhetaSegmentador {
    margin-left: -10px !important;
    // margin-right: 5px !important;
  }
  .fixPaddingLeftExperiencia{
    padding-left: 14px !important;
  }
  .logo-br{
    max-width: 180px;
    margin-left: 3px !important;

  }
  .fontQueSonRipleyPuntosGo {
    width: 97% !important;
  }
  .fontTodoPuedeCanjear{
    width: 97% !important;
    margin-top: 10px;
  }
  .fontTextoPromoHome {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .hr-rpg-grey{
    border-top: 1.5px solid #737373;
  }

  .hr-rpg-gold{
    border-top: 1.5px solid #F8C21C;
  }

  .divBannerIzquierdoOMP {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 0px;
  }

   .subtitleBoxCanjePromo{
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 138.97% !important;
    margin-top: -5px;

  }
  .imgLogoCanjePromo {
    margin-top: -10px;
    margin-left: -2px;
    width: 125px;
  }
  .imgTarjetaCanjePromo {
    margin-top: -110px !important;
    margin-left: 142px !important;
  }
  .titleBoxCanjePromoPrice {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 138.97% !important;
    display: flex !important;
    align-items: center !important;
  }
  .boxCanjePromoContainer {
    margin-left: 0px !important;
  }
  .ptitleBoxCanjePromo{
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 138.97% !important;
    margin-top: -10px !important;
  }
  .boxCanjePromo {
    width: 335px !important;
    height: 216px !important;
    position: relative;
    left: 50%;
    margin-bottom: -9px ;
  }
  .fixIzquierdaMargenCanjePromo {
    margin-left: -165px !important;
  }
  .fixDerechaMargenCanjePromo{
    margin-left: -165px !important;
  }

  .fixMargenResponsiveCP {
    margin-right: 0% !important;
  }
  .contenedorAunSinTarjeta{
    margin-top: -40px !important;
  }
  .prev, .next {
    top: 40% !important;
  }
  .cont-descuentos_fix {
    margin-top: -80px !important;
  }
  .contenedorCategorias{
    margin-top: -50px !important;
  }
  .btn_categoria-rpg {
    margin-left: 0px !important;
  }
  .t7movilR {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 28px !important;
    text-align: center !important;
  }
  .fixResponsiveContentVitrina{
    // margin-left: -10% !important;
    margin-top: -80%;
    margin-left: 5%;
    width: 100%;
  }
  .divCategoriaLeft {
    margin-left: 0px !important;
    margin-top: -10px !important;
  }
  .divCategoriaRight {
    margin-left: 0px !important;
    margin-top: -30px !important;
  }
  .divItemCategoria{
    margin-bottom: 20px !important;
    max-width: 80% !important;
    margin: auto !important;
  }
  .divItemCategoriaDescripcion {
    max-width: 80% !important;
    margin: auto !important;
    margin-top: 20px !important;
  }



  .fixPaddingLeftImgPreguntaFrecuentaCat{
    padding-left: 0px !important;
    margin-top: 5px !important;
    margin-top: -4px !important;
  }
  .itemRequisitosCanjearPuntos{
    margin-left: 0px !important;
  }
  #listaItemTutorialRptoGo{
    width: 100%;
  }
  .fixwidthRespuestaPreguntaFrecuente{
    width: 100% !important;
  }
  .itemRespuestaPreguntaFrecuente{
    text-align: justify !important;
    margin-right: 10px;
  }

  #appModalTerminosBeneficios {
    padding-right: 0px !important;
  }
  .img-mobile{
    height: 100%;
  }
  .img-mobile_sin_tarjeta {
    height: 100%;
  }
  .box-pg{
    max-width: 100%;
  }
  .box-no-client{
    max-width: 100%;
  }
  .box-no-client_child{
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .box-center-pg{
    width: 100%;
    height: 25px
  }
 }

// Medium devices (tablets, less than 992px)

@media (min-width: 768px) and (max-width: 991.98px) {
  #nroDocumentoCP {
    max-width: none;
    width: 250px !important;
  }
  #nroDocumentoCP2 {
    max-width: none;
    width: 250px !important;
  }
  #nroDocumento {
    max-width: none;
    width: 250px !important;
  }
  .divBannerIzquierdoPRO {
    padding-top: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .itemVinhetaSegmentador {
    margin-left: -10px !important;
    // margin-right: 5px !important;
  }
  .logo-br{
    max-width: 180px;
    margin-left: 3px !important;

  }
  .fontQueSonRipleyPuntosGo {
    width: 97% !important;
  }
  .fontTodoPuedeCanjear{
    width: 97% !important;
    margin-top: 10px;
  }
  .hr-rpg-grey{
    border-top: 1.5px solid #737373;
  }

  .hr-rpg-gold{
    border-top: 1.5px solid #F8C21C;
  }
  .img-mobile{
    height: 90%;
  }
  .img-mobile_sin_tarjeta {
    height: 90%;
  }
  .box-no-client_child{
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
 }

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .itemVinhetaSegmentador {
    margin-left: -10px !important;
    // margin-right: 5px !important;
  }
  .logo-br{
    max-width: 160px;

  }
  .fixMargenResponsiveCP {
    margin-right: 30% !important;
  }
  .img-mobile{
    height: 100%;
  }
  .img-mobile_sin_tarjeta {
    height: 100%;
  }
  .box-no-client_child{
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.app-opacity {
  background-color: rgba($color: #FFF, $alpha: 0.9);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;

  //display: none;
  //display: inline-block;

  .app-loading {
    background: url('/assets/img/loading.svg') center center no-repeat;
    width: 200px;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 47.5%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 110;

    @include respond-to(575px){
      left: 41%;
    }
  }
}

.open-loading {
  display: inline-block !important;
}

.close-loading {
  display: none !important;
}
