.cont-vitrina{
  h1,h2,h3,h4,h5,h6,p,a,span{
    line-height: 1.2;
  }
}
.cont-vitrina__footer{
  height: 100px;
  padding: 25px 15px;
  text-align: center;
}
.vitrina__pie{
  background-color: $darkPurple1;
  height: 105px;
  padding: 15px 0;
  
  @include respond-to(768px){
    height: 85px;
  }
}
@include respond-to(767px){
  .vitrina__pie{
    .t3{
      font-size: 15.5px;
      margin: 0 15px;
      padding: 15px;
      width: 100%;
    }
  }
}
.vitrina{
  background-color: #563278;
  overflow: hidden;
  background-color: #fff !important;
  .vitrina__img{
    width: 100%;
    text-align: center;
    background-color: #e0e2e1;
    img{
      width: 100%;
      //max-width: 580px;
    }
  }
  .vitrina__img_noSlider{
    width: 100%;
    text-align: center;
    img{
      width: 100%;
      max-width: 100%;
    }
  }
  .vitrina__contenido{
    margin-top: 35px;
    margin-bottom: 25px;
    //max-width: 280px;
    // max-width: 520px;
    max-width: 55%;
    width: 100%;
    display: inline-block;
    white-space: normal;
    p{
      word-break: break-word;
    }
    a{
      // padding: 4% 10%;
      margin-right: 5%;
    }
  }

  .vitrina__contenido__categoria__plus{
    margin-top: 50px;
    margin-bottom: 25px;
    //max-width: 280px;
    // max-width: 622px;
    max-width: 55%;
    width: 100%;
    display: inline-block;
    white-space: normal;
    p{
      word-break: break-word;
    }
    a{
      // padding: 4% 10%;
      margin-right: 5%;
    }
  }

  @include respond-to(767px){
    background-color: #fff !important;
    .vitrina__contenido{
      //min-height: 200px;
      //min-height: AUTO;
      //max-width: none;
      max-width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      span{
        flex-grow: 1;
        &:nth-child(2){
          display: flex;
          justify-content: space-around;
          flex-grow: 5;
          align-items: flex-start;
        }
      }
      a{
        text-align: center;
        padding: 1rem 2.5rem;
        margin: 0 5%;
      }
      p, h3, h2{
        color: $grey1;
      }
    }
  }
  @include respond-to(576px){
    .vitrina__contenido{
      max-height: 70px;
      //min-height: 200px;
      flex-direction: column;
      align-items: flex-start;
      //justify-content: space-around;
      //justify-content: flex-start;
      span{
        width: 100%;
        justify-content: flex-start !important;
        display: inline-block;
        flex-grow: 0;
        &:nth-child(2){
          flex-grow: 0;
        }
      }
      a{
        margin-left: 0;
      }
    }
  }
}
.bg-yellow{
  background: #ffb900;
}
.btnFix{
  @include respond-to(765px){
    width: 136px !important;
    padding: 0.4rem 1rem !important;
  }
}
.frame {
  width: 100%;
  position: relative;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  white-space: nowrap;
  .slides {
    display: inline-block;
    padding-left: 0;
    width: 100%;
    li {
      position: relative;
      display: inline-block;
    }
  }
}
.slider{
  .js_next,.js_prev{
    //background: #fff;
    width: 40px;
    height: 40px;
    opacity: 1 !important;
    position: absolute;
    top: 50%;
    &:after{
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      //background: transparent url('../../img/svg/i-slider-next.svg') no-repeat;
      background: transparent  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA5IDE2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0OC4yICg0NzMyNykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi93aGl0ZS1hcnJvdzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzLz4KICAgIDxnIGlkPSJpY29uL2Fycm93LXNsaWRlciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3LjAwMDAwMCwgLTEzLjAwMDAwMCkiPgogICAgICAgIDxnIGlkPSJpY29uL2dyZXktYXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTMuMDAwMDAwKSIgZmlsbD0iIzk3OTk5QiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTguNjc2MTkyMjUsOC4zMDUwNzE5NSBMMS44ODc0MTA5NywxNC44MDM1MzM3IEMxLjQ1NTU2MjQ4LDE1LjIxNzEyNzggMC43NTUzOTQxNzUsMTUuMjE3MTI3OCAwLjMyMzc1NTMzNiwxNC44MDM1MzM3IEMtMC4xMDc5MTg0NDUsMTQuMzkwMzA3NSAtMC4xMDc5MTg0NDUsMTMuNzIwMDk0NCAwLjMyMzc1NTMzNiwxMy4zMDY5MDE2IEw2LjMzMDc3ODY4LDcuNTU2NzU1OTMgTDAuMzIzOTMwMDQ2LDEuODA2ODEwOTIgQy0wLjEwNzc0MzczNSwxLjM5MzQxNzQ5IC0wLjEwNzc0MzczNSwwLjcyMzI3MTI1NCAwLjMyMzkzMDA0NiwwLjMxMDA0NTA3MSBDMC43NTU2MDM4MjcsLTAuMTAzMzQ4MzU3IDEuNDU1NzM3MTksLTAuMTAzMzQ4MzU3IDEuODg3NTg1NjgsMC4zMTAwNDUwNzEgTDguNjc2MzY2OTYsNi44MDg2MDcxNSBDOC44OTIyMDM4NSw3LjAxNTMyMDU4IDksNy4yODU5NTQ2MyA5LDcuNTU2NzIyNDggQzksNy44Mjc2MjQxMiA4Ljg5MTk5NDIsOC4wOTg0NTg4NiA4LjY3NjE5MjI1LDguMzA1MDcxOTUgWiIgaWQ9IlNoYXBlIi8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') center center no-repeat;
      // background: url("../img/svg/i-xs-flecha-derecha-arrows.svg") center center no-repeat;
    }
  }
  @include respond-to(768px){
    .js_next,.js_prev{
      opacity: .8;
      
    }
  }
  .js_prev{
    &:after{
      transform: rotate(180deg);
    }
  }
  .prev, .next {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: block;
    cursor: pointer;
    svg{
      width: 35px;
    }

    @include respond-to(768px){
      top: 50%;

    }

  }
  .next {
    right: 0;
  }
  .prev {
    left: 0;
  }
}
.js_slides{
  margin-bottom: 0;
}
.nav-slider{
  background-color: $darkPurple1;
  height: 105px;
  padding: 15px 0;
  h3{
    font-size: 13.5px;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  p{
    font-size: 12px;
    opacity: .4;
    line-height: 1.6;
  }
  .col{
    height: 105px;
    padding: 15px;
    cursor: pointer;
    @extend .transition1;
    &:hover{
      background: $darkPurple2;
    }
  }
  .active{
    background: $darkPurple2;
  }
}
.vitrina_noSlider{
  .vitrina__contenido{
    .t3{
      font-size: 18px;
    }

  }
  @include respond-to(991px){
    .vitrina__contenido{
      //max-width: 100%;
      .t3{
        font-size: 15px;
      }
      .s1{
        font-size: 12px;
      }
    }
  }
}
