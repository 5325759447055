.contenedorRipleyPuntos {
  height: auto;
  width: 100%;
  margin-top: 20px;
}
.contenido-info {
  display: inline-block;
  white-space: normal;
}
.contenido-info-rpg {
  display: inline-block;
  white-space: normal;
}
.contenido-seccion {
  background: #ffffff;
  border: 0.998296px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #f2f4f9;
  border-radius: 7px;
  width: 100%;
}
.seccionRipleyPuntosInfo {
  height: auto;
  width: 100%;
  margin-top: 20px;
}
.contenido-consultar {
  background: #ffffff;
  border: 0.998296px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #f2f4f9;
  border-radius: 7px;
}
.cont-consultar {
  background: #fff;
  padding: 20px 0;
  ul {
    padding-left: 0;
  }
  ul,
  li {
    list-style: none;
  }
  .carousel-control {
    &-prev,
    &-next {
      top: 45%;
      opacity: 1;
    }
    &-prev {
      left: -15px !important;
      &:after {
        left: 0 !important;
      }
    }
    &-next {
      right: -15px;
      &:after {
        right: 0;
      }
    }
  }
}
.box-consulta-puntos {
  margin-top: 10px;
  margin-bottom: -50px;
  padding: 0;
  padding-right: 5px;
  padding-left: 5px;
  .box-box-consulta-puntos__header {
    //text-transform: uppercase;
    width: 100%;
    text-align: left;
    @include font-style($montserrat, 700, 11.5px, #fff);
    letter-spacing: 1px;
    padding: 8px 15px;
    position: relative;
  }
  .box-box-consulta-puntos__contenido {
    width: 100%;
    padding: 0 16px;
    min-height: 200px;
    img {
      max-height: 55px;
      margin: 10px 0;
    }
    .link {
      margin: 20px 0;
    }
  }
  @include respond-to(991px) {
    .box-box-consulta-puntos__contenido {
      align-content: flex-start;
    }
    .box-box-consulta-puntos__contenido > .col-12 {
      text-align: left;
      img {
        max-height: 55px;
      }
    }
  }
}
.boxRPConoceMas {
  max-width: 670px;
  width: 100%;
  height: 195px;
  background: #7747ae;
  border: 1.0482px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #f2f4f9;
  border-radius: 8px;
  margin: auto;
  background-image: url(/assets/img/ripley_puntos/star-fondo.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.detailBoxConoceMas {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 138.97%;
  color: #fafafa;
  text-align: left;
}
.btnConoceMasBoxRP {
  color: #fafafa !important;
  align-items: center;
  text-align: center;
  width: 160px;
  height: 32px;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  margin-top: 30px;
  padding-left: 5.5%;
}
.btnConoceMasBoxRP:hover {
  background: #fafafa;
  color: #523178 !important;
}
.text_PCalificables {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #8c4799;
}
.text_PCalificables:hover {
  text-decoration-line: underline;
  color: #8c4799;
}
.opacity_ModalPC{
  z-index: 1 !important;
}
.hide-tab{
  display: none !important;
}
.subtitle_links_home{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #404040;
}

