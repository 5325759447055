@import "utilities/variables";
@import "utilities/base";

//header
@import "utilities/header";
//fin header styles

//vitrina styles
@import "utilities/vitrinas";
//fin vitrina styles

//tarjetas styles
@import "utilities/ripleypuntos";
//fin tarjetas styles

//beneficios styles
@import "utilities/beneficios";
//fin beneficios styles

//footer styles
@import "utilities/footer";
//fin footer styles

//style tr pages
.cont-dosColumnas {
  margin-top: 40px;
  margin-bottom: 30px;
  @include respond-to(576px) {
    margin-bottom: 25px;
  }
  .boxItemList {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .boxItemList_img {
    max-width: 70px;
    width: 100%;
    text-align: center;
    margin: 10px;
  }
  @include respond-to(576px) {
    .boxItemList {
      margin-bottom: 25px;
    }
    .boxItemList_img {
      min-width: 60px;
    }
  }
  .boxItemList_list {
    margin-left: 70px;
    padding: 0;
    list-style: none;
    li {
      width: 200px;
      display: inline-block;
      padding-bottom: 10px;
      font-family: $montserrat;
      font-size: 12.5px;
      padding-left: 15px;
      position: relative;
      &:before {
        position: absolute;
        content: "·";
        left: 0;
        top: -8px;
        @include font-style($montserrat, 700, 25px, $purple1);
      }
    }
  }
  .boxButtons {
    .btnRed {
      max-width: 200px;
      width: 100%;
      text-align: center;
    }
  }
  .boxButtons_links {
    .link {
      margin: 5px 20px;
    }
  }
  @include respond-to(576px) {
    .boxButtons {
      a {
        max-width: 100%;
        margin-top: 25px;
      }
    }
  }
}
#slide-dosColumnas {
  position: relative;
  .boxItemList {
    min-height: 110px;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
  p {
    text-align: center;
  }
  a {
    top: 80px !important;
    opacity: 1;
    &:after {
      left: 0;
    }
  }
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
  .prev {
    left: -15px;
  }
  .next {
    right: -15px;
  }
}
.boxOportunidades {
  background-color: #f8f8f8;
  padding-top: 60px;
  @include respond-to(576px) {
    padding-top: 15px;
    .t3 {
      font-size: 15.5px;
    }
  }
  .card {
    &:nth-child(2) {
      margin: 0 30px;
    }
    width: 100%;
    max-width: 280px;
    padding: 15px;
    border-radius: 0;
    border: none;
    //margin: 15px 0;
    box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
    &.active {
      box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
    }
    a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
      margin-top: 10px;
    }
  }
  .card_imgTarjeta {
    margin-top: 10px;
    img {
      padding-right: 5px;
      max-width: 50px;
      width: 100%;
    }
    p {
      padding-left: 5px;
    }
  }
}
.boxdosColumnas_tr {
  .t3 {
    font-size: 16.5px;
  }
  img.order-first {
    width: 100%;
    max-width: 200px;
  }
  #slide-dosColumnas {
    margin: 15px 0;
  }
  @include respond-to(576px) {
    .t3 {
      font-size: 14px;
      width: 55%;
      margin-left: 10px;
    }
    img.order-first {
      max-width: 40%;
    }
  }
  .boxdosColumnas_tr__header {
    p {
      font-size: 12.5px;
    }
    ul {
      padding-left: 15px;
      li {
        color: $purple1;
        margin-bottom: 15px;
      }
    }
    &_noPadding {
      padding-left: 0;
      @include respond-to(991px) {
        img {
          //max-width: 100px;
          width: 100%;
        }
      }
    }
  }
}
.boxdescubreTR {
  //background-color: #f8f8f8;
  padding-top: 60px;
  padding-bottom: 50px;

  background: rgba(248, 248, 248, 1);
  background: -moz-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(248, 248, 248, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#ffffff', GradientType=0 );

  .t3 {
    text-align: center;
    font-size: 16.5px;
    padding-bottom: 15px;
  }
  .cardTR {
    margin: 20px 15px;

    img {
      width: 100%;
      max-width: 90px;
    }
    span {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .firstBlock {
    height: 140px;
    p {
      text-align: center;
    }
  }
  .listItems {
    list-style: none;
    padding: 0 15px;
    li {
      min-height: 50px;
      text-align: right;
      padding: 0 15px;
      border-bottom: 1px solid $lightGrey2;
      line-height: 1.5;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .columnTR {
    background-color: #fff;
    padding: 0 15px 20px 15px;
    &:not(active) {
      border: 1px solid #efefef;
    }
    &.active {
      z-index: 100;
      box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
    }
    img {
      width: 100%;
      max-width: 100px;
      margin-bottom: 15px;
    }
    .listItems {
      width: 100%;
      img {
        width: 100%;
        max-width: 15px;
        margin-bottom: 0;
      }
    }
    a {
      margin-top: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
    }
  }
  @include respond-to(576px) {
    padding-top: 15px;
  }
}

.box_dap {
  padding-top: 50px;
  border-top: 1px solid #efefef;
  margin-bottom: 40px;
  img {
    width: 100%;
  }
}

.bottomInfo {
  position: absolute;
  bottom: 0px;
}

//styles pat.pug
.cont-accordion_form {
  margin-top: 100px;
  .purple {
    color: $darkPurple1;
  }
  .box_accordion {
    button {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: left;
      background: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      padding: 0 40px 15px 15px;
      outline: none;
      border-bottom: 1px solid #efefef;
      margin-top: 15px;
    }
    a {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: left;
      background: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      padding: 0 40px 15px 15px;
      outline: none;
      margin-top: 18px;
    }
    a.pf {
      padding: 0;
      color: #4c4c4c;
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
    }
    ul {
      list-style: none;
      padding: 0 15px 15px 15px;
      p {
        padding: 10px 0;
      }
    }
  }
}

.box_accordion {
  button {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0 40px 15px 15px;
    outline: none;
    margin-top: 15px;
  }
}

//fin styles pat.pug
// #vitrinaBancaPorInternet {
//   align-items: center!important;
//   justify-content: center!important;
//    @include respond-to(575px){
//       margin-left: 10%;
//       margin-top: -16%;
//     }
// }
.bgVitrina_ {
  &pageDetails {
    position: relative;
    min-height: 300px;
    background: top right no-repeat;
    background-size: auto 100%;
    img {
      width: 100%;
    }
    @include respond-to(1024px) {
      min-height: 200px;
    }
  }
  &img {
    min-height: 300px;
    background: top right no-repeat;
    background-size: auto 100%;
    @include respond-to(1024px) {
      min-height: 200px;
    }
  }

  &txt {
    padding-top: 20px;
    padding-bottom: 20px;
    * {
      color: #333;
      margin-bottom: 0;
    }
    @include respond-to(575px) {
      background-color: #fff;
    }
    @include respond-to(768px) {
      .t2 {
        font-size: 18px;
      }
    }
  }
}
.condiciones {
  &:before {
    content: "";
    height: 5px;
    width: 250px;
    background-color: $red1;
    position: absolute;
    top: -5px;
    left: 0;
  }
}

.pageDetails {
  box-shadow: 0 2px 12px 0 rgba(222, 222, 222, 0.5);
  //rgba(222,222,222,0.5);
  background-color: #fff;
  position: relative;
  margin-top: -50px;
  margin-bottom: 0px;
  padding: 30px;
  @include respond-to(991px) {
    margin-top: 0px;
  }
  @include respond-to(767px) {
    padding: 25px;
  }
  h2 {
    margin-bottom: 25px;
  }
  ul {
    padding: 0;
    list-style: none;
  }
  &_2col_list {
    ul:not(._1col_list) {
      -moz-column-count: 2;
      -moz-column-gap: 50px;
      -webkit-column-count: 2;
      -webkit-column-gap: 50px;
      column-count: 2;
      column-gap: 50px;
      @include respond-to(767px) {
        -moz-column-count: 1;
        -moz-column-gap: 50px;
        -webkit-column-count: 1;
        -webkit-column-gap: 50px;
        column-count: 1;
        column-gap: 50px;
      }
    }
    ul._1col_list {
      -moz-column-count: 1;
      -moz-column-gap: 50px;
      -webkit-column-count: 1;
      -webkit-column-gap: 50px;
      column-count: 1;
      column-gap: 50px;
    }
    li.none-content {
      color: #000;
      position: relative;
      @include font-style($montserrat, 500, 12.5px, $grey1);
      width: 100%;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.8;
      &.none-padding {
        padding-left: 0px;
      }

      & ul {
        -moz-column-count: 1;
        -moz-column-gap: 50px;
        -webkit-column-count: 1;
        -webkit-column-gap: 50px;
        column-count: 1;
        column-gap: 50px;
        & li {
          color: #000;
          position: relative;
          @include font-style($montserrat, 500, 12.5px, $grey1);
          width: 100%;
          margin-bottom: 0px;
          padding-left: 15px;
          padding-right: 15px;
          line-height: 1.8;
          &:before {
            content: "";
            position: absolute;
            width: 5px;
            height: 1px;
            //border-radius: 100%;
            background-color: $grey1;
            top: 8px;
            left: 0;
          }
        }
      }
    }
    li:not(.none-content) {
      color: #000;
      position: relative;
      @include font-style($montserrat, 500, 12.5px, $grey1);
      width: 100%;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.8;
      &:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $purple1;
        top: 8px;
        left: 0;
      }
    }
  }
  &_1col_list {
    ul {
      @include respond-to(767px) {
        -moz-column-count: 1;
        -moz-column-gap: 50px;
        -webkit-column-count: 1;
        -webkit-column-gap: 50px;
        column-count: 1;
        column-gap: 50px;
      }
    }
    li {
      color: #000;
      position: relative;
      @include font-style($montserrat, 500, 12.5px, $grey1);
      width: 100%;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.8;
      // &:before{
      //   content: '';
      //   position: absolute;
      //   width: 5px;
      //   height: 5px;
      //   border-radius: 100%;
      //   background-color: $purple1;
      //   top: 8px;
      //   left: 0;
      // }
    }
  }
  &_2col_download {
    li {
      margin-bottom: 15px;
      p {
        opacity: 0.8;
        margin-bottom: 20px;
      }
      a {
        width: 100%;
        display: block;
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #efefef;
      }
      .download {
        position: relative;
        padding-right: 40px;
        &:before {
          content: "";
          background: transparent url("/assets/img/svg/i-download.svg") no-repeat
            center center;
          background-size: contain;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 0;
          left: calc(100% - 30px);
        }
      }
    }
  }
  &_2col_download1 {
    li {
      margin-bottom: 15px;
      p {
        opacity: 0.8;
        margin-bottom: 20px;
      }
      a {
        width: 100%;
        display: block;
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #efefef;
      }
      .download {
        position: relative;
        padding-right: 40px;
        &:before {
          content: "";
          background: transparent;
          background-size: contain;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 0;
          left: calc(100% - 30px);
        }
      }
    }
  }

  &_2col_bases {
    li {
      margin-bottom: 40px;
    }
    .box-links {
      a {
        margin-right: 15px;
      }
    }
  }
  &_sitioSeguro,
  &_preguntasFrecuentes {
    &.cont-accordion_form {
      margin-top: 0;
    }
    .semiBold {
      margin-bottom: 0;
      margin-top: 40px;
    }
    .s1 {
      font-size: 13.5px !important;
    }
    .s3 {
      font-size: 18px !important;
    }
    .arrow-down {
      &.active {
        &:after {
          transform: rotate(180deg);
          opacity: 0.5;
        }
      }
      &:after {
        @extend .transition1;
        right: 3px;
      }
    }
    .collapse,
    .collapsing {
      p {
        font-size: 12px;
        line-height: 1.5;
        padding: 0 20px;
        color: rgba(#333, 0.8);
        font-weight: 600;
      }
      li {
        color: #000;
        position: relative;
        @include font-style($montserrat, 500, 12.5px, $grey1);
        width: 100%;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.8;
        a {
          color: $purple1;
          &:hover {
            text-decoration: underline;
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: $purple1;
          top: 8px;
          left: 0;
        }
      }
      .col-4 {
        p {
          padding: 0;
        }
      }
      & > p:first-child,
      & > ul:first-child,
      & > .col-12:first-child {
        padding-top: 15px;
      }
    }
  }
  &_terminos {
    &.cont-accordion_form {
      margin-top: 0;
    }
    .semiBold {
      margin-bottom: 20px;
      margin-top: 0px;
    }
    .s1 {
      font-size: 13.5px !important;
    }
    .s3 {
      font-size: 18px !important;
    }
    .arrow-down {
      &.active {
        &:after {
          transform: rotate(180deg);
          opacity: 0.5;
        }
      }
      &:after {
        @extend .transition1;
        right: 3px;
      }
    }

    .collapse,
    .collapsing {
      p {
        font-size: 12px;
        line-height: 1.5;
        padding: 0 20px;
        color: rgba(#333, 0.8);
        font-weight: 600;
      }
      li {
        color: #000;
        position: relative;
        @include font-style($montserrat, 500, 12.5px, $grey1);
        width: 100%;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.8;
        a {
          color: $purple1;
          &:hover {
            text-decoration: underline;
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: $purple1;
          top: 8px;
          left: 0;
        }
      }
      .col-4 {
        p {
          padding: 0;
        }
      }
      & > p:first-child,
      & > ul:first-child,
      & > .col-12:first-child {
        padding-top: 15px;
      }
    }
  }
  &_sitioSeguro {
    .collapse,
    .collapsing {
      ul {
        margin-top: 15px;
        list-style: disc;
        padding: 0 30px;
      }
      li {
        font-size: 12px;
        margin-bottom: 0;
        padding: 0;
        &:before {
          display: none;
        }
        a {
          font-size: 12px;
        }
      }
      a {
        color: $purple1;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  &_sucursales {
  }
  &_preguntasFrecuentes {
    #nav-tab {
      margin-top: 0;
    }
    p.semiBold {
      margin-top: 35px;
      margin-bottom: 15px;
    }
    .s2 {
      font-weight: 600;
      font-size: 12px;
    }
    a.purple {
      color: $purple1;
    }
    .noList {
      list-style: none;
      padding: 0;
      li {
        padding-left: 0;
        padding-right: 0;
        &:before {
          content: none;
        }
      }
    }
    .collapse,
    .collapsing {
      & > p:first-child,
      & > ul:first-child,
      & > .col-12:first-child {
        padding-top: 15px;
      }
    }
  }
  &_masBanca {
    .colorItemAcordion {
      font-size: 15px !important;
    }
    #nav-tab {
      margin-top: 10px;
      margin-left: -20px;
      @include respond-to(575px) {
        margin-left: -2%;
        margin-top: -20px;
      }
    }
    #nav-tab.nav-tabs .nav-link.active {
      color: #8c4799;
      border-bottom: 3px solid #8c4799;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
    }

    #nav-tab.nav-tabs {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
    }

    #nav-tab .nav-link {
      min-width: 110px;
      padding: 0px 18px 3px 18px;
      margin-left: 20px;
    }

    #nav-tab.nav-tabs .nav-link {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      border-bottom: 3px solid #a5a5a5;
    }
    #nav-tab.nav-tabs a:hover {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      border-bottom: 3px solid #a5a5a5;
    }
    ul {
      padding: 0;
      list-style: none;
    }

    .s1 {
      font-size: 13.5px !important;
    }

    .arrow-right {
      &.active {
        &:after {
          transform: rotate(180deg);
          opacity: 0.5;
        }
      }
      &:after {
        @extend .transition1;
        right: 3px;
      }
    }
  }
  &_masApp {
    .colorItemAcordionApp {
      font-size: 15px !important;
    }
    #nav-tab {
      margin-top: 10px;
      margin-left: -20px;
      @include respond-to(575px) {
        margin-left: -2%;
        margin-top: -20px;
      }
    }
    #nav-tab.nav-tabs .nav-link.active {
      color: #8c4799;
      border-bottom: 3px solid #8c4799;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      background-color: #ffffff;
    }

    #nav-tab.nav-tabs {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
    }

    #nav-tab .nav-link {
      min-width: 110px;
      padding: 0px 18px 3px 18px;
      margin-left: 20px;
    }

    #nav-tab.nav-tabs .nav-link {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      border-bottom: 3px solid #a5a5a5;
    }
    #nav-tab.nav-tabs a:hover {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      border-bottom: 3px solid #a5a5a5;
    }
    ul {
      padding: 0;
      list-style: none;
    }

    .s1 {
      font-size: 13.5px !important;
    }

    .arrow-right {
      &.active {
        &:after {
          transform: rotate(180deg);
          opacity: 0.5;
        }
      }
      &:after {
        @extend .transition1;
        right: 3px;
      }
    }
  }
  &_descuentoDestacado {
    height: 100%;
    max-height: 900px;
    background-color: #fff;
    @include respond-to(767px) {
      max-height: 100%;
    }
  }
}

.pageDetails_gobCorp {
  padding-top: 50px;
  #nav-tab {
    margin-bottom: 30px;
    margin-top: 0;
    a {
      border-bottom: 1px solid rgba($grey1, 0.25) !important;
      span {
        font-size: 12.5px;
        text-align: left;
        color: $grey2;
        margin-top: 3px;
        font-weight: 600;
      }
      &.active {
        background-color: $lightGrey2;
        &:after {
          content: "";
          height: 10px;
          width: 10px;
          position: absolute;
          top: 18px;
          right: 15px;
          transform: rotate(-90deg);
          background: transparent url("/assets/img/svg/i-chevron-down.svg") center
            center no-repeat;
          background-size: 100% auto;
        }
        span {
          color: $grey1;
        }
      }
      &:hover {
        background-color: $lightGrey2;
      }
      .boxImg {
        min-width: 30px;
        margin-right: 15px;
      }
      @include respond-to(991px) {
        min-height: 45px;
        height: auto;
        background-color: $lightGrey1 !important;
        border: 1px solid rgba($grey1, 0.2) !important;
        padding: 10px 15px !important;
        display: none !important;
        &.active {
          justify-content: flex-start !important;
          display: flex !important;
          &:after {
            display: block !important;
            cursor: pointer;
            height: 43px !important;
            width: 42px !important;
            background-color: $lightGrey2 !important;
            transform: rotate(0deg) !important;
            background-size: 15px !important;
            top: 0 !important;
            right: 0 !important;
            border-left: 1px solid rgba($grey1, 0.25) !important;
          }
        }
        &.selectTabs {
          display: flex !important;
          margin-bottom: 0;
        }
        &:after {
          display: none !important;
        }
        .boxImg {
          max-width: 30px;
          display: inline-block;
        }
      }
    }
  }
  #nav-tabContent {
    p {
      margin-bottom: 25px;
      &.grey {
        line-height: 1.8;
        opacity: 0.8;
      }
      &.description {
        margin-bottom: 1px;
      }
      &.s1 {
        margin-bottom: 15px;
      }
      strong {
        color: #000;
      }
    }
  }
  select {
    @include font-style($montserrat, 600, 13.5px, $grey2);
    padding: 10px 8px;
    -webkit-appearance: none;
    border-radius: 0;
    color: #333;
    background-color: #fff;
    border-color: rgba($grey1, 0.13);
    background: url("/assets/img/svg/i-chevron-down.svg") no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 10px;

      height: 30px;
      width: 30px;
    }
  }
  &-gerency {
    img {
      margin-bottom: 20px;
    }
  }
  .btnDocumentos {
    padding: 10px 5px 10px 8px;
    display: inline-block;
    width: 100%;
    color: $grey1;
    background: $lightGrey1 url("/assets/img/svg/i-download.svg") no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
    margin-bottom: 10px;
    &:hover {
      background-color: $lightGrey2;
      text-decoration: none;
    }
  }
}
.pageDetails_ripleyPuntosGo {
  #nav-tab {
    border-bottom: 1px solid $lightGrey2;
    padding-left: 25px;
    padding-right: 25px;
    @include respond-to(991px) {
      border-bottom: none;
      padding: 0;
    }
  }
  #nav-tabContent {
    margin-top: 25px;
    .box-img {
      text-align: center;
      width: 100%;
      max-width: 40px;
      margin-right: 15px;
    }
    .item-list {
      margin: 15px 0;
    }
  }
  #nav-queCanjear {
    img[alt="giftcards"] {
      margin-top: 50px;
      margin-bottom: 25px;
      max-width: 90%;
    }
    h2.purple {
      margin-bottom: 25px;
    }
    .cardBox {
      margin-top: 25px;
      margin-bottom: 25px;
      &:first-child {
        -webkit-box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
        -moz-box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
        box-shadow: 0 2px 20px 0 rgba(202, 202, 202, 0.5);
      }
      &_img {
        border: 1px solid #ededed;
        border-bottom: none;
        background-color: $grey1;
        height: 150px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        span {
          letter-spacing: 1px;
          text-transform: uppercase;
          background-color: $yellow1;
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px 15px;
          z-index: 2;
        }
      }
      &_contenido {
        border: 1px solid #ededed;
        //border-top: none;
        padding: 15px;
        p {
          padding: 10px 0;
        }
      }
    }
    .contGiftCards {
      &_header {
        border-bottom: 1px solid rgba($grey1, 0.3);
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 25px;
        p {
          text-align: center;
        }
      }
      .btnRed {
        margin: 25px 0;
        width: 100%;
        max-width: 150px;
      }
      &_giftCards {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(#ededed, 0.5);
        &:nth-child(5) {
          margin-bottom: 25px;
        }
        img {
          max-width: 75px;
          min-width: 50px;
        }
        .bg {
          padding: 8px 10px;
          margin: 10px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          &_C1 {
            background-color: #a45e9f;
          }
          &_C2 {
            background-color: #da3e7d;
          }
          &_C3 {
            background-color: #df6522;
          }
          &_C4 {
            background-color: #dca626;
          }
        }
        .color {
          &_C1 {
            color: #a45e9f;
          }
          &_C2 {
            color: #da3e7d;
          }
          &_C3 {
            color: #df6522;
          }
          &_C4 {
            color: #dca626;
          }
        }
      }
    }
  }
  #nav-millasLatamPass {
    .boxConversion {
      margin: 25px 0;
      > p {
        padding-bottom: 15px;
        border-bottom: 1px solid #ededed;
      }
      &_millas {
        padding: 15px 0;
        p[alt*="="] {
          margin-left: 15px;
          margin-right: 15px;
        }
        p[align="right"],
        p[align="left"] {
          width: 100%;
          max-width: 200px;
          font-size: 14px;
        }
        p[align="right"].s1,
        p[align="left"] {
        }
      }
    }
  }

  .boxDetalle {
    &Acumulacion,
    &Acumulacion_noBorder {
      margin: 15px 0;
      padding: 15px 0;
      border-bottom: 1px solid $lightGrey2;
      &_noBorder {
        border-bottom: none;
      }
      .boxTarjeta {
        text-align: center;
        img {
          max-width: 90px;
        }
        p {
          padding: 5px 0;
        }
        @include respond-to(575px) {
          margin-bottom: 15px;
          img {
            max-width: 70px;
          }
          p {
            padding: 0 0 0 15px;
          }
        }
      }
      .boxPuntos {
        span {
          margin: 0 10px;
        }
        @include respond-to(575px) {
          span {
            margin: 0 7px 0 0;
          }
          .lightGrey3 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.detalle-av-sav-cc {
  &__title {
    margin-top: 80px;
    h1 {
      line-height: 1.3;
    }
  }
  .pageDetails {
    &:before {
      content: "";
      height: 5px;
      width: 250px;
      background-color: $yellow1;
      position: absolute;
      top: -5px;
      left: 0;
    }
    margin-top: 30px;
    padding: 0;
  }
  .box-detalle {
    &__contenido {
      padding: 45px;
      h2 {
        font-size: 16.5px;
      }
      &-vigencia {
        margin: 25px 0;
        img {
          margin-right: 20px;
        }
      }
      &-legal {
        margin-bottom: 30px;
        font-weight: 400;
      }
    }
    &__img {
      overflow: hidden;
      img {
        height: 100%;
      }
    }
    .btnRed {
      margin: 0 15px 15px 0;
    }
  }
}
.pageDescuento {
  padding: 0;
  .box-beneficios {
    &__img {
      overflow: hidden;
      padding: 0;
      position: relative;
      span {
        width: 100%;
        text-align: center;
        background-color: $purple1;
        padding: 10px 15px;
        position: absolute;
        top: 0;
        left: 0;
        @include font-style($montserrat, 700, 11.5px, #fff);
        letter-spacing: 1px;
        text-transform: uppercase;
        z-index: 10;
      }
      img {
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }
    }
    &__contenido {
      padding: 50px;
      position: relative;
      &-logo {
        position: absolute;
        top: 50px;
        right: 50px;
        img {
          max-height: 55px;
        }
      }
      p {
        margin-bottom: 15px;
      }
      .txtDcto {
        margin-bottom: 0;
        font-size: 29.5px;
      }
      &-vigencia,
      &-tarjeta {
        margin-bottom: 25px;
        display: inline-block;
        img {
          max-height: 30px;
          width: auto;
          margin-right: 15px;
        }
      }
    }
    @include respond-to(767px) {
      &__img {
        min-height: 200px;
        img {
          height: auto;
          width: 100%;
        }
      }
      &__contenido {
        padding: 20px;
        &-vigencia,
        &-tarjeta {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.contenedorCreditos {
  margin-top: 50px;
  margin-bottom: 70px;
  &_box {
    border-radius: 6px;
    background-color: $darkPurple1;
    margin-top: 50px;
    padding: 40px 20px 20px 20px;
    position: relative;
    min-height: 250px;
    &-txtHeader {
      border-radius: 6px 0 6px 0;
      background: $yellow1;
      padding: 8px 15px;
      position: absolute;
      top: 0;
      left: 0;
      text-transform: uppercase;
      @include font-style($montserrat, 700, 12.5px, #fff);
    }
    .bold {
      font-weight: 900 !important;
    }
    .btnRed {
      margin-top: 20px;
      padding: 10px 0;
      width: 80%;
    }
    .yellow {
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 28.5px;
    }
  }
}

.tabsToSelect {
  #nav-tab {
    @include respond-to(991px) {
      a {
        width: 100%;
        //height: 45px;
        background-color: $lightGrey1 !important;
        border: 1px solid rgba($grey1, 0.2) !important;
        padding: 11.5px 16px !important;
        display: none !important;
        text-align: left;

        span {
          // font-size: 12.5px;
          font-size: 11.5px;
          text-align: left;
          color: $grey2;
          margin-top: 3px;
          font-weight: 600;
        }
        &.active {
          background-color: $lightGrey2;
          justify-content: flex-start !important;
          display: flex !important;

          &:after {
            content: "";
            position: absolute;
            background: transparent url("/assets/img/svg/i-chevron-down.svg") center
              center no-repeat;
            display: block !important;
            cursor: pointer;
            height: 43px !important;
            width: 42px !important;
            background-color: $lightGrey2 !important;
            transform: rotate(0deg) !important;
            background-size: 15px !important;
            top: 0 !important;
            right: 0 !important;
            border-left: 1px solid rgba($grey1, 0.25) !important;
          }
          span {
            color: $grey1;
          }
        }
        &:hover {
          background-color: $lightGrey2;
        }
        .boxImg {
          min-width: 30px;
          max-width: 30px;
          //margin-right: 15px;
          margin-right: 5px;
          display: inline-block;
        }
        &.selectTabs {
          display: flex !important;
          margin-bottom: 0;
        }
        &:after {
          display: none !important;
        }
      }
    }
  }
}

.cont-beneficios .tabsToSelect {
  #nav-tab {
    @include respond-to(767px) {
      a {
        width: 100%;
        //height: 45px;
        background-color: $lightGrey1 !important;
        border: 1px solid rgba($grey1, 0.2) !important;
        padding: 10px 15px !important;
        display: none !important;

        span {
          font-size: 12.5px;
          text-align: left;
          color: $grey2;
          margin-top: 3px;
          font-weight: 600;
        }

        &.active {
          background-color: $lightGrey2;
          justify-content: flex-start !important;
          display: flex !important;

          &:after {
            content: "";
            position: absolute;
            background: transparent url("/assets/img/svg/i-chevron-down.svg") center
              center no-repeat;
            display: block !important;
            cursor: pointer;
            height: 43px !important;
            width: 42px !important;
            background-color: $lightGrey2 !important;
            transform: rotate(0deg) !important;
            background-size: 15px !important;
            top: 0 !important;
            right: 0 !important;
            border-left: 1px solid rgba($grey1, 0.25) !important;
          }

          span {
            color: $grey1;
          }
        }

        &:hover {
          background-color: $lightGrey2;
        }

        .boxImg {
          min-width: 30px;
          max-width: 30px;
          margin-right: 15px;
          display: inline-block;
        }

        &.selectTabs {
          display: flex !important;
          margin-bottom: 0;
        }

        &:after {
          display: none !important;
        }
      }
    }
  }
}

// .pageDetails_masBanca .tabsToSelect {
//   #nav-tab {
// @include respond-to(767px) {
//   a {
//     width: 100%;
//     //height: 45px;
//     background-color: $lightGrey1 !important;
//     border: 1px solid rgba($grey1, .2) !important;
//     padding: 10px 15px !important;
//     display: none !important;

//     span {
//       font-size: 12.5px;
//       text-align: left;
//       color: $grey2;
//       margin-top: 3px;
//       font-weight: 600;
//     }

//     &.active {
//       background-color: $lightGrey2;
//       justify-content: flex-start !important;
//       display: flex !important;

//       &:after {
//         content: '';
//         position: absolute;
//         background: transparent url("../img/svg/i-chevron-down.svg") center center no-repeat;
//         display: block !important;
//         cursor: pointer;
//         height: 57px !important;
//         width: 42px !important;
//         background-color: $lightGrey2 !important;
//         transform: rotate(0deg) !important;
//         background-size: 15px !important;
//         top: 0 !important;
//         right: 0 !important;
//         border-left: 1px solid rgba($grey1, .25) !important;
//       }

//       span {
//         color: $grey1;
//       }
//     }

//     &:hover {
//       background-color: $lightGrey2;
//     }

//     .boxImg {
//       min-width: 30px;
//       max-width: 30px;
//       margin-right: 15px;
//       display: inline-block;
//     }

//     &.selectTabs {
//       display: flex !important;
//       margin-bottom: 0;
//     }

//     &:after {
//       display: none !important;
//     }
//   }
// }
// @include respond-to(991px){
//   a {
//     width: 100%;
//     //height: 45px;
//     background-color: $lightGrey1 !important;
//     border: 1px solid rgba($grey1, .2) !important;
//     padding: 11.5px 16px !important;
//     display: none !important;
//     text-align: left;

//     span {
//       // font-size: 12.5px;
//       font-size: 11.5px;
//       text-align: left;
//       color: $grey2;
//       margin-top: 3px;
//       font-weight: 600;
//     }
//     &.active {
//       background-color: $lightGrey2;
//       justify-content: flex-start !important;
//       display: flex !important;

//       &:after {
//         content: '';
//         position: absolute;
//         background: transparent url("../img/svg/i-chevron-down.svg") center center no-repeat;
//         display: block !important;
//         cursor: pointer;
//         height: 57px !important;
//         width: 42px !important;
//         background-color: $lightGrey2 !important;
//         transform: rotate(0deg) !important;
//         background-size: 15px !important;
//         top: 0 !important;
//         right: 0 !important;
//         border-left: 1px solid rgba($grey1, .25) !important;
//       }
//       span {
//         color: $grey1;
//       }
//     }
//     &:hover {
//       background-color: $lightGrey2;
//     }
//     .boxImg {
//       min-width: 30px;
//       max-width: 30px;
//       //margin-right: 15px;
//       margin-right: 5px;
//       display: inline-block;
//     }
//     &.selectTabs {
//       display: flex !important;
//       margin-bottom: 0;
//     }
//     &:after {
//       display: none !important;
//     }
//   }
// }
//   }
// }

.pageDetails_masBanca {
  .collapse li,
  .collapsing li {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #58595b;
  }
}
.pageDetails_masApp {
  .collapse li,
  .collapsing li {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #58595b;
  }
}

#listaItemTutorial {
  margin-top: 23px;
  margin-left: 20px;
}

//Sucursales Iframe
.pageDetails_sucursales .col-12 iframe,
.iframe-client-form iframe {
  width: 100%;
  height: 1000px;
  border: 0px;
}
//Hazte cliente
.container-client-form {
  margin-top: 50px;
}
//Simulador Consumo
.simulatorLastText {
  font-size: 14px;
  margin-top: 10px;
  line-height: 160%;
  text-align: left;
  color: #4c4c4c;
  margin-bottom: 0px;
}
//tutorial
.listaTutorial {
  margin-top: 20px;
  font-family: $montserrat;
  font-size: 15.5px;
  &:last-child {
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .listaTutorial {
    margin-top: 10%;
  }
}
.mbottom50 {
  margin-bottom: 50px;
}
//Avance y super avance
.legalBox {
  p,
  a {
    font-family: Montserrat;
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: $lightGrey3;
    margin-bottom: 10px;
    text-decoration: none !important;
  }
}
//Underline Fix
.no-underline {
  text-decoration: none !important;
}

table > thead > tr > th {
  background: #e8e8ea;
  color: #533278;
  font-family: Montserrat;
  font-size: 14px;
  text-align: center;
}

table > tbody > tr > td {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 1em;
  color: #333333;
}

// table tr {
//   text-transform: lowercase;
// }

.text-horary {
  color: #000;
  font-size: 14px;
  text-align: center;
}

p.s2.purple.semiBold {
  padding-right: 0;
}

.cab_comite {
  font-size: 14px;
  color: #523178;
  font-weight: bold;
}

.det_comite {
  font-size: 14px;
  color: #58595b;
  font-weight: bold;
}

.det {
  font-size: 14px;
  color: #58595b;
  margin-bottom: 0px;
}

.headerAcumula {
  border-radius: 0px 8px 8px 0px;
  height: 58px;
  color: white !important;
  padding-top: 4px !important;
}
.flexfillheader {
  background: #6b409c !important;
  border-radius: 0px 8px 8px 0px;
  -moz-border-radius: 0px 8px 8px 0px;
  -webkit-border-radius: 0px 8px 8px 0px;
  height: 60px;
  border-top: 1px solid #6b409c;
  margin-top: -2px;
  margin-right: -1px;
  margin-left: 15px;

  position: absolute;
  right: 0px;
  top: 1px;
  // padding-right: 52px;
  // padding-left: 53px;
}

.flexfillheader-br {
  background: #6b409c !important;
  margin-top: -1px;
  margin-right: -1px;
  margin-left: 32px;

  position: absolute;
  right: 0px;
  top: 1px;
  // padding-right: 52px;
  // padding-left: 53px;
}

.headerAcumula-br {
  height: 60px;
  color: white !important;
  padding-top: 4px !important;
}

@media (min-width: 768px) {
  .width-tab {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .cab_comite {
    font-size: 12px;
  }

  .det_comite {
    font-size: 12px;
  }

  .det {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .center-horizontal {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .cab_comite {
    font-size: 10px;
  }

  .det_comite {
    font-size: 10px;
  }

  .det {
    font-size: 10px;
  }

  // .divConsultaRP {
  //   margin-left: 5px;
  // }
  // .topAdemasAccede{
  //   margin-top: -20px !important;
  // }
  // .divAdemasAccede {
  //   margin-top: -20px;
  // }
}

@media (min-width: 992px) {
  .width-tab {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .width-tab {
    width: 25%;
  }
}

.mobile-modal-layout {
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  position: fixed;
  top: 0;
  padding-bottom: 30px;
}

.mobile-modal-layout .overlay {
  height: auto;
  width: 86%;
  margin: auto;
  background: white;
  vertical-align: center;
  box-shadow: 0 2px 20px 0 #333;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  border-radius: 10px;
  align-self: center;
}

.mobile-modal-layout .overlay .close {
  font: 13px "montserratregular";
  width: 30px;
  color: #4c4c4c;
  height: 30px;
  float: left;
  position: absolute;
  right: 5px;
  top: 5px;
  background-size: 15px;
  cursor: pointer;
  background-position: center;
  opacity: 1;
}

.i-close-light {
  background-image: url(/assets/img/ic_close.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobile-modal-layout .overlay .info {
  padding: 15px 0;
}

.mobile-modal-layout .overlay .info p {
  color: #000;
}

.mobile-modal-layout .overlay .info-app {
  padding: 14px 24px;
}

.mobile-modal-layout .overlay .btn-container {
  margin: 24px 0 15px 0;
}

.mobile-modal-layout .overlay .info-app .app-icon {
  width: 65px;
  height: 65px;
  float: left;
  margin-right: 10px;
}
.i-logo-app-store {
  background-image: url(/assets/img/app-icon.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobile-modal-layout .overlay .info-app .info-app-store {
  text-align: left;
}

.mobile-modal-layout .overlay .info-app .info-app-store h1 {
  color: #000;
}

.mobile-modal-layout .overlay .overlay-image img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.mobile-modal-layout .overlay .info-app .info-app-store .app-stars {
  height: 24px;
}

.mobile-modal-layout .overlay .info-app .info-app-store .app-stars .star {
  height: 12px;
  width: 12px;
  display: inline-block;
}

.i-star-app-full {
  background: url(/assets/img/i-star-app-full.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.info-app-store h1 {
  margin-bottom: 0px;
  font-size: 12px;
}

.mobile-modal-layout .overlay .btn-container .btn {
  max-width: calc(100% - 48px);
  min-width: 160px;
  height: 37px;
  padding: 8px 20px;
  clear: both;
  margin-top: 16px;
}

.yellow-btn {
  background: #f2ad4b;
}

.btn {
  color: white;
  border-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(164, 164, 164, 0.5);
  transition: ease-in 200ms;
  max-width: 230px;
  display: block;
  font-size: 15px;
  width: 200px;
  margin: auto;
  font-weight: bold;
}

.fontRipleyfono {
  font-size: 28px;
}

.marginTopText {
  margin-top: 10px;
}

.fontLibro {
  color: #4c4c4c;
  text-decoration: underline;
}

.box_accordion {
  .row {
    align-items: center;
    img {
      padding-left: 17px;
    }
  }
}

ul {
  li {
    span {
      color: purple;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// .margin-bottom{
//   margin-bottom: 20px;
// }

.margin-top {
  margin-top: 30px;
}

.margin-topPageDetails {
  margin-top: 40px;
}

.observacion {
  margin: 5px 0px;
  color: $grey1;

  @include respond-to(767px) {
    margin: 5px 20px;
  }
}

.condicionDescuento {
  margin: 12px 0 0 0;
  color: #808080 !important;
}

.carousel-indicators li {
  background-color: #adadad;
}

.margin-left {
  margin-left: 10px;
}

.beneficio-align {
  margin: 15px 0;

  @include respond-to(575px) {
    margin-bottom: 15px;
    min-height: 110px;
  }
}

.titleGeneral {
  font-size: 18px;
}

.titleGeneralResponsive {
  font-size: 18px;
  text-align: center;
}

.centerText {
  text-align: center;
}

.nav-item {
  span {
    margin-right: 25px;
  }
}

.breadcrumbEnlace {
  color: #3f3f41;
}

.breadcrumb-item.active {
  color: #8c4799;
}

.breadcrumb {
  background: #fff;
}

.breadcrumbContainer {
  margin-top: 15px;
}

.breadcrumbNav {
  margin-left: -15px;
}

.link-caracteristica {
  padding-left: 15px;
  position: relative;
  color: $purple1;
  width: auto !important;
}

ul.banner {
  padding-left: 0px;
  li.list-banner {
    color: #4c4c4c;
    font-family: $montserrat;
    font-size: 14px;
    padding-left: 12px;
    font-weight: normal;
    &:before {
      position: absolute;
      content: "·";
      left: 0;
      top: -10px;
      @include font-style($montserrat, 700, 25px, #4c4c4c);
    }
  }
}

// REQUERIMIENTO BANCA POR INTERNET
#vitrinaflotante {
  max-width: 419px;
  max-height: 410px;
  width: 419px;
  height: 400px;
  margin-top: -60px;
  position: relative;
  background-color: #fff;
  margin-left: 70px;
  @include respond-to(577px) {
    max-width: 91%;
    margin-top: -25px;
    margin-left: 5%;
    height: 80%;
  }
  @include respond-to(767px) {
    height: 97%;
  }
}

.divCerrarFlotante {
  padding: 12px;
  border: none;
  margin-top: -50px;
  padding-top: 10px;
}
.divCerrarFlotanteSpan {
  width: 20px;
  float: right;
  position: absolute;
  top: -25px;
  right: -10px;
  margin-top: 0px;
  margin-right: 0px;
}
.divCerrarFlotanteSpanMovil {
  width: 20px;
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
  margin-right: 0px;
  z-index: 1000;
}

.SpanFlotante {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  cursor: hand;
}

.modalVerPelicula {
  position: relative;
  padding: 0px;
}

#modalVerVideo {
  top: 19%;
  z-index: 1000;
  @include respond-to(931px) {
    padding-left: 17px;
  }
}

.dgModal {
  max-width: 898px;
  margin: 20px auto;
}
.sxModal {
  max-width: 676px;
  margin: 20px auto;
}
.modalFondoVerVideo {
  background-color: #523178;
}

#cartoonVideo {
  width: 100%;
  height: 100%;
}

.marcoImagen {
  width: 898px;
  height: 475px;
}
.divSeguridad {
  margin-top: -40px;
  padding-bottom: 50px;
}
.style2ItemBeneficioSMS {
  margin-left: -15px;
}
.fixMargenTextoMasBanca {
  margin-top: 60px !important;
  @include respond-to(767px) {
    margin-top: 6px !important;
  }
}

.imgFormaIngresoMovil {
  // max-width: 312px;
  // min-height: 199px;
  width: 272px;
  height: 171px;
}
.imgFormaIngresoMovilApp {
  // max-width: 271px;
  // min-height: 353px;
  width: 271px;
  height: 353px;
}
@media (max-width: 575px) {
  .divSeguridad {
    margin-top: 30px;
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .divSeguridad {
    margin-top: 30px;
    padding-bottom: 0px;
  }
}

.responsHB {
  @include respond-to(432px) {
    .circuloItemBeneficio {
      text-align: center;
      margin-top: 10px;
    }
  }

  @include respond-to(575px) {
    #nav-tab .nav-link {
      min-width: 90px;
      padding: 0px;
    }

    .imgFormaIngreso0Movil {
      margin-left: 5px;
      margin-top: 125px;
    }
    .imgFormaIngresoMovil {
      margin-top: 25px;
      // margin-left: 5%;
      // width: 93%;
    }
    .imgFormaIngresoMovilApp {
      margin-top: 25px;
      // margin-left: 5%;
      // width: 93%;
    }
    .imgAfiliateSMSToken {
      margin-top: -40px;
      margin-left: 5%;
      width: 94%;
    }
    .imgAfiliateSMSToken2 {
      margin-left: 5%;
      width: 94%;
    }
    .imgAfiliateSMSToken3 {
      margin-left: 5%;
      width: 94%;
    }
    .style1imgAfiliateSMSToken2 {
      margin-left: 0px;
    }
    .style1imgAfiliateSMSToken3 {
      margin-left: 0px;
    }
    .style1ItemBeneficioSMS {
      margin-top: -10px;
      width: 98%;
      margin-left: 0%;
      margin-bottom: 20px;
    }
    .style2ItemBeneficioSMS {
      margin-left: 0%;
      margin-top: -10px;
      width: 98%;
      margin-left: 2%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .style0ItemBeneficioSMS {
      margin-left: 0%;
    }

    .colorItemAcordion {
      line-height: 20px;
    }
    .colorItemAcordionApp {
      line-height: 20px;
    }
    .espacioItemCol2 {
      font-size: 16px;
      margin-right: -2px;
    }
    .detalleBancaInternet {
      line-height: 20px;
      margin-left: -20px;
      margin-right: 8px;
    }
    .tituloBancaInternet {
      font-weight: normal;
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 30px;
      margin-top: -20px;
      margin-left: 15px;
    }
    .negritaSpan {
      font-weight: normal;
      font-size: 22px;
      line-height: 29px;
    }
    .circuloItemBeneficio {
      text-align: center;
      margin-top: 10px;
    }

    .DetalleTituloItemBeneficio {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .DetalleTituloItemBeneficioApp {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .btnletraIngresar {
      margin-left: 10px;
      margin-bottom: 40px;
    }
    .btnletraIngresarApp {
      margin-left: 10px;
      margin-bottom: 40px;
    }
    .TituloIngresar {
      margin-left: 9px;
      margin-top: 20px;
      padding-top: 25px;
    }
    .DescripcionIngresar {
      margin-left: 10px;
      text-align: left;
      font-size: 16px;
      line-height: 22px;
    }
    .imgFormaIngreso {
      margin-left: -15px;
      width: 340px;
    }
    .imgFormaIngresoApp {
      margin-left: -15px;
      width: 340px;
    }
    .itemDetalleBeneficio {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -15px;
    }
    .ItemBeneficio {
      margin-bottom: 25px;
    }
    .margenFilaQuesePuedeHacer {
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left: -20px;
    }
    .espacioBottom {
      margin-bottom: 50px;
      margin-top: 160px;
    }
    .espacioBottomApp {
      margin-bottom: 50px;
      margin-top: 230px;
    }
    .tituloDetalleMasBanca {
      margin-left: 0px;
    }
    .detalleMasBanca {
      margin-right: 0px;
      margin-left: 0px;
    }
    .detalleMasBancaSubItem {
      margin-right: 0px;
      margin-left: 20px;
    }
    .margenIzquierdaTutorial {
      margin-left: -20px;
      margin-right: -30px;
      margin-top: 15px;
    }
    #listaItemTutorial {
      margin-left: 1px;
      margin-right: 10px;
    }
    #listaItemTutorial > li {
      font-size: 15px;
    }
    .detalleTutoriales {
      margin-left: 0px;
      padding-left: 0px;
    }
    .verVideo {
      margin-left: 0px;
    }
    .TextoMasBanca {
      margin-left: 0px;
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 20px;
    }
    .TextoMasBancaApp {
      margin-left: 0px;
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 22px !important;
    }
    .strongTextoPurpleMasBanco {
      font-size: 22px;
    }
    .pageDetails {
      width: 335px;
    }
  }
  @include respond-to(768px) {
    .style0ItemBeneficioSMS {
      margin-left: 0%;
    }
    .imgFormaIngreso0Movil {
      margin-left: 5px;
      margin-top: 125px;
    }
    .imgFormaIngresoMovil {
      margin-top: 25px;
      // margin-left: 5%;
    }
    .imgFormaIngresoMovilApp {
      margin-top: 25px;
      margin-left: -8%;
    }
    .TituloIngresar {
      margin-top: 10px;
      padding-top: 25px;
    }
    .espacioBottomApp {
      margin-bottom: 50px;
      margin-top: 230px;
    }
    .espacioBottom {
      margin-bottom: 50px;
      margin-top: 130px;
    }
  }
  @include respond-to(991px) {
    .style0ItemBeneficioSMS {
      margin-left: 0%;
    }
    .imgFormaIngreso0 {
      margin-left: -10px;
    }
    #divcontainersectionTop {
      // margin-bottom: -90px;
    }
    .detalleBancaInternet {
      margin-bottom: 60px;
    }
    .imgFormaIngreso {
      margin-top: 30px;
      margin-left: 20px;
      padding-bottom: 0px;
    }
    .imgFormaIngresoApp {
      margin-top: 30px;
      margin-left: 20px;
      padding-bottom: 0px;
    }
    .margenFilaQuesePuedeHacer {
      margin-top: -50px;
      margin-bottom: 0px;
    }
    .margenLeftFilaQuesePuedeHacer {
      margin-left: 5px;
    }
    .TextoMasBanca {
      margin-left: 0px;
    }
    .TextoMasBancaApp {
      margin-left: 0px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .style0ItemBeneficioSMS {
    margin-left: 10%;
  }
  .style2ItemBeneficioSMS {
    margin-left: 10%;
  }

  .margenLeftFilaQuesePuedeHacer {
    margin-left: 20px;
  }
  .imgEstrella {
    margin-top: -407px;
    margin-left: 60%;
  }
  // .imgEstrellaTop {
  //   margin-top: -697px;
  //   margin-left: 450px;
  //   position: relative;
  // }
  // .divAdemasAccede {
  //   margin-left: 0px;
  // }
  .detalleBancaInternet {
    width: 320px;
  }
}

.imgMasBanca0 {
  margin-top: 174px;
  margin-left: -65px;
  position: absolute;
  z-index: 1;
}
.imgMasBanca {
  margin-top: 30px;
  z-index: -1;
}

.margenFilaQuesePuedeHacer {
  margin-bottom: -50px;
}

.itemDetalleBeneficio {
  margin-left: 60px;
  margin-right: 60px;
}

.TituloIngresar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
  color: #523178;
  margin-left: 45px;
  margin-top: 70px;
  padding-top: 10px;
  @include respond-to(767px) {
    font-size: 26px;
  }
}

.DescripcionIngresar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #58595b;
  margin-left: 45px;
  margin-top: 20px;
  margin-right: 45px;
  @include respond-to(767px) {
    margin-top: 20px;
    margin-right: 10px;
  }
}

.btnletraIngresar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  background-color: #e7273a !important;
  margin: 15px;
  margin-left: 45px;
  margin-top: 30px;
  width: 173px;
  height: 46px;
}

.btnletraIngresarApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  background-color: #e7273a !important;
  margin: 15px;
  margin-left: 45px;
  margin-top: 30px;
  width: 173px;
  height: 46px;
}

.imgFormaIngreso0 {
  margin-top: 165px;
  margin-left: -230px;
  position: absolute;
  z-index: 1;
}
.imgFormaIngreso0Movil {
  width: 120px;
  height: 127px;
  margin-top: 160px;
  margin-left: -230px;
  position: absolute;
  z-index: 1;
}

.imgFormaIngreso {
  margin-top: -100px;
  margin-left: -190px;
  padding-bottom: 10px;
  width: 525px;
  height: 329px;
  z-index: -1;
}
.imgFormaIngresoApp {
  margin-top: -112px;
  margin-left: -100px;
  padding-bottom: 10px;
  width: 346px;
  height: 453px;
  z-index: -1;
}

.imgAfiliateSMSToken {
  max-width: 457px;
  max-height: 284px;
  margin-left: 30%;
  margin-top: 50px;
}
.style0imgAfiliateSMSTokenMovil {
  text-align: center;
}
.style1imgAfiliateSMSToken2Movil {
  text-align: center;
  margin-top: 45px;
  margin-bottom: 10px;
}
.style2imgAfiliateSMSToken3Movil {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.imgAfiliateSMSToken2 {
  max-width: 426px;
  max-height: 301px;
  margin-top: 25px;
}
.imgAfiliateSMSToken3 {
  max-width: 426px;
  max-height: 301px;
  margin-left: 25%;
  margin-top: 10px;
}

.TextoBeneficios {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
  text-align: center;
  color: #8c4799;
  margin-bottom: 60px;
  margin-top: -110px;
  @include respond-to(767px) {
    font-size: 30px;
  }
}
.TextoBeneficiosApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
  text-align: center;
  color: #8c4799;
  margin-bottom: 60px;
  margin-top: -210px;
  @include respond-to(767px) {
    font-size: 30px;
  }
}

.TextoMasBanca {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 28px;
  color: #523178;
  margin-top: 120px;
  margin-left: -10px;
  margin-bottom: 50px;
}
.TextoMasBancaApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 28px;
  color: #523178;
  margin-top: 45px;
  margin-left: -10px;
  margin-bottom: 50px;
}

.fixMargin {
  margin-left: -22px;
  margin-top: 80px;
  @include respond-to(767px) {
    margin-top: 50px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px !important;
    line-height: 28px;
  }
}

.content-Panel {
  margin-left: 12px;
  margin-bottom: 40px;
}

.ItemBeneficio {
  text-align: center;
  margin-bottom: 10px;
}
.circuloItemBeneficio {
  margin-left: 0px;
  height: 52px;
}
.circuloVerVideo {
  width: 23px;
  height: 23px;
  border-radius: 50px;
  background: #8c4799;
  background: transparent url("/assets/img/svg/i-vector.svg") no-repeat center center;
  float: left;
}
.verVideo {
  margin-top: 25px;
  margin-left: 15px;
  margin-bottom: 40px;
}

.TituloItemBeneficio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 20px;
  @include respond-to(767px) {
    font-size: 20px;
    line-height: 22px;
  }
}

.DetalleTituloItemBeneficio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #58595b;
  margin-left: 15px;
  margin-right: 15px;
}
.DetalleTituloItemBeneficioApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #58595b;
  margin-left: 19px;
  margin-right: 19px;
}

.ItemBeneficioSMS {
  margin-top: 10%;
  width: 92%;
}
.circuloItemBeneficioSMS {
  width: 42px;
  height: 42px;
  margin-left: -30px;
  margin-bottom: 30px;
}

.rowSMSToken {
  margin-top: 15px;
  margin-bottom: 12px;
  @include respond-to(767px) {
    margin-top: 0px;
  }
}
.style0ItemBeneficioSMS {
  width: 92%;
  @include respond-to(767px) {
    width: 100%;
  }
}
.style0TituloItemBeneficioSMS {
  width: 90%;
  @include respond-to(767px) {
    width: 100%;
  }
}
.style1ItemBeneficioSMS {
  width: 92%;
  margin-left: 12%;
}
.style1TituloItemBeneficioSMS {
  width: 70%;
  @include respond-to(767px) {
    width: 90%;
  }
}
.style1TituloItemBeneficioSMSApp {
  width: 80%;
  @include respond-to(767px) {
    width: 90%;
  }
}
.style2TituloItemBeneficioSMSApp {
  width: 85%;
  @include respond-to(767px) {
    width: 90%;
  }
}

.style1imgAfiliateSMSToken2 {
  margin-left: 15%;
}
.style1imgAfiliateSMSToken3 {
  margin-left: 25%;
}
.style2DetalleTituloItemBeneficioSMSApp {
  // margin-left: 20px;
}

.TituloItemBeneficioSMS {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 25px;
  margin-left: 15px;
  text-align: left;
  @include respond-to(767px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-left: 0px;
    text-align: center;
  }
}
.DetalleTituloItemBeneficioSMS {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  color: #58595b;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  @include respond-to(767px) {
    line-height: 22px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.DetalleTituloItemBeneficioSMSApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  color: #58595b;
  margin-left: 21px;
  margin-right: 25px;
  margin-bottom: 20px;
  @include respond-to(767px) {
    line-height: 22px;
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.clsVisualiza {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #f2c94c;
}
.divConsultaRP {
  margin-top: -15px;
  margin-left: -20px;
  z-index: 899;
}
.topAdemasAccede {
  // margin-top: 10px;
  margin-top: 25px !important;
}
.divAdemasAccede {
  margin-top: -30px;
  margin-left: 2px;
}
.tituloBancaInternet {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 50px;
  margin-top: -30px;
  margin-left: -10px;
}
.negritaSpan {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  color: #ffffff;
}

.detalleBancaInternet {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 2px;
  margin-left: 25px;
  margin-right: 20px;
}

.detalleMasBanca {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4c4c4c;
  list-style: none;
  margin-right: 50px;
  margin-left: 30px;
  margin-top: 30px;
}

.detalleMasBancaSubItem {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
  list-style: none;
  margin-right: 50px;
  // margin-left: 30px;
  margin-top: 10px;
}

.imgQuePuedoHacerBancaInternet {
  width: 471px;
  height: 275px;
}

.detalleMasBancaTitulo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #4c4c4c;
  list-style: none;
  margin-right: 50px;
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.tituloDetalleMasBanca {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #523178;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-top: 50px;
}
.strongTextoMasBanco {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #523178;
}
.strongTextoPurpleMasBanco {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 28px;
  color: #523178;
}
.divFixImagen {
  margin-top: -275px;
  margin-bottom: 260px;
  @include respond-to(992px) {
    margin-top: 0px;
  }
}

.imgQuePuedoHacerBancaInternet0 {
  max-width: 809px;
  max-height: 580px;
}
.imgEstrella {
  margin-left: -150px;
  margin-top: -120px;
  @include respond-to(1200px) {
    opacity: 0;
  }
}
.imgEstrellaTop {
  margin-top: -377px;
  margin-left: 100px;
  position: absolute;
}

.imgQuePuedoHacerBancaInternetMovil {
  min-width: 344px;
  min-height: 208px;
  width: 344px;
  height: 208px;
  margin-top: 4px;
  @include respond-to(374px) {
    margin-left: -8%;
  }
}

.espacioBottom {
  margin-bottom: 90px;
}
.espacioBottomApp {
  margin-bottom: 90px;
}
.espacioItem {
  margin-bottom: 15px;
}

.espacioItem0 {
  margin-bottom: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #8c4799;
  margin-top: 40px;
}

.espacioItem2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #58595b;
  margin-bottom: 8px;
  max-width: 607px;
}
.espacioItemCol2 {
  margin-bottom: 10px;
}
.rowContainerFluid {
  padding-bottom: 40px;
}

.menuTopRight {
  margin-top: -60px;
  margin-right: 0px;
}
.tutoComoRegistro {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #454343;
}
.LinkAtrasPage {
  font-family: Montserrat;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  color: #523178;
}
.margenIzquierdaTutorial {
  margin-left: 17px;
  margin-top: 18px;
}

.fontVerVideo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 135.4%;
  color: #424242;
  padding-left: 15px;
}

.col_box_accordion {
  button {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0 40px 15px 15px;
    outline: none;
    border-bottom: 1px solid #efefef;
    margin-top: 15px;
    line-height: 37px;
  }
}

.col_box_accordionCondicionesBeneficiosCategorias {
  button {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0 40px 15px 15px;
    outline: none;
    margin-top: 15px;
    line-height: 37px;
  }
}

.colorItemAcordion {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #8c4799;
}
.colorItemAcordionApp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #8c4799;
}

.boxDegradadoHB {
  background: rgba(248, 248, 248, 1);
  background: -moz-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(248, 248, 248, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}

.containersectionTop {
  background-color: #523178;
  padding-top: 120px;
  padding-bottom: 50px;
}
#divcontainersectionTop {
  // margin-bottom: -90px;
  margin-top: 20px;
}

.divContainerAfiliateSMSToken {
  background: linear-gradient(180deg, #f2f4f9 0%, #f2f4f9 100%);
  // background: #F2F4F9;
  // transform: matrix(1, 0, 0, -1, -1, -1);
}
.divBottomSeguridad {
  // padding-bottom: 60px;
  @include respond-to(767px) {
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}

.bgVitrinaBxI_ {
  &pageDetails {
    background: url("/assets/img/landingbxi/bancainternet_normal.png") top right
      no-repeat;
    background-size: auto 100%;
    background-color: #ebeae8;
    position: relative;
    height: 240px;
    @include respond-to(992px) {
      background: url("/assets/img/landingbxi/bancainternet_sm.png") top right
        no-repeat;
      background-size: auto 100%;
      background-color: #ebeae8;
      position: relative;
    }
    @include respond-to(575px) {
      height: 200px;
      background: url("/assets/img/landingbxi/bancainternet_xs.png") top right
        no-repeat;
      background-size: auto 100%;
      background-color: #ebeae8;
      position: relative;
    }
  }
  // &txt{
  //   padding-top: 50px;
  //   padding-left: 140px;
  //   padding-right: 50%;
  //   @include respond-to(992px){
  //     padding-left: 60px;
  //   }
  //   @include respond-to(768px){
  //     padding-left: 40px;
  //   }
  //   @include respond-to(575px){
  //     padding-top: 20px;
  //     padding-left: 16px;
  //     padding-right: 45%;
  //     p{
  //       padding-right: 20px;
  //     }
  //     .t1{
  //       font-size: 20px;
  //     }
  //     .t4{
  //       font-size: 12px;
  //     }
  //     .s1{
  //       font-size: 11.5px;
  //     }
  //   }
  //   @include respond-to(374px){
  //     padding-right: 35%;
  //   }
  // }
}

.bgVitrinaApp_ {
  &pageDetails {
    background: url("/assets/img/landingapp/app_normal.png") top right no-repeat;
    background-size: auto 100%;
    background-color: #533279;
    position: relative;
    height: 240px;
    @include respond-to(992px) {
      background: url("/assets/img/landingapp/app_sm.png") top right no-repeat;
      background-size: auto 100%;
      background-color: #533279;
      position: relative;
    }
    @include respond-to(575px) {
      height: 200px;
      background: url("/assets/img/landingapp/app_xs.png") top right no-repeat;
      background-size: auto 100%;
      background-color: #533279;
      position: relative;
    }
  }
}

.background-gray {
  background: rgba(66, 66, 66, 0.5);
  height: 100%;
}

.background-grayApp {
  background: rgba(66, 66, 66, 0.57);
  height: 100%;
}

@media (max-width: 575px) {
  .divConsultaRP {
    margin-left: 5px;
  }
  .topAdemasAccede {
    margin-top: -20px !important;
  }
  .divAdemasAccede {
    margin-top: -20px;
  }
  .espacioItem2 {
    margin-left: -17px;
  }
  #divcontainersectionTop {
    margin-top: 0px;
  }
}
.divfontComoloHago {
  text-align: left;
  @include respond-to(767px) {
    text-align: center;
  }
}
.fontComoloHago {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #8c4799;
  margin-left: 15px;
}

.video-btn:hover {
  color: #8c4799;
}
.fontComoloHago:hover {
  color: #8c4799;
}

.StyleVitrinaflotante {
  //box-shadow: 0 1px 50px 0 #dedede;
  box-shadow: 0px 40px 30px rgba(230, 231, 243, 0.3);
  box-shadow: 0px 24px 30px rgba(230, 231, 243, 0.3);
  -webkit-box-shadow: 0px 24px 30px rgba(230, 231, 243, 0.3);
  -moz-box-shadow: 0px 24px 30px rgba(230, 231, 243, 0.3);
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .imgEstrellaTop {
    margin-top: -697px;
    margin-left: 450px;
    position: relative;
  }
  .divAdemasAccede {
    margin-left: -10px;
  }
  .margenFilaQuesePuedeHacer {
    margin-bottom: 5px;
  }
}

.fixUnaLinea {
  width: 360px;
}
@media (max-width: 450px) {
  .fixUnaLinea {
    width: 100%;
  }
}