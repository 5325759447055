@import "utilities/variables";
@import "utilities/base";

.img-gold-prime{
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 56%;
}

.contenedor-ripley-puntos{
    margin-top: 50px;
    margin-bottom: 70px;
    &_box{
      border-radius: 6px;
      background-color: $darkPurple1;
      margin-top: 50px;
      padding: 40px 20px 20px 20px;
      position: relative;
      min-height: 250px;
      &-txtHeader{
        border-radius: 6px 0 6px 0;
        background: $yellow1;
        padding: 8px 15px;
        position: absolute;
        top: 0;
        left: 0;
        text-transform: uppercase;
        @include font-style($montserrat,700,12.5px,#fff);
      }
      .bold{
        font-weight: 900 !important;
      }
      .btnRed{
        margin-top: 20px;
        padding: 10px 0;
        width: 80%;

      }
      .yellow{
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 28.5px;
      }
    }
  }
  .contenedorClientPrime{
    height: auto;
    width: 100%;
    //background: #edf1fc;
    // background: linear-gradient(to top, #edf1fc, #ffffff);
    background: linear-gradient(180deg, rgba(240, 238, 255, 0) 0%, #F0EEFF 49.48%);

    // .sol-producto:hover{
    //   background-color:  #5E388A;
    //   // padding: 30px 0px;
    // }

    // .link-product:hover{
    //   text-decoration: none;
    //   cursor: pointer;
    // }

  }
  .btnConsultarPrime {
    color: #fff !important;
    background: #523178 !important;
    padding: 12px 0 !important;
    max-width: none;
    width: 70% !important;
    //margin: 15px 0 10px 0;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 4px !important;
    display: inline-block !important;
    cursor: pointer;
    max-width:60% !important;
    font-family: Montserrat;
}
.btnConsultarPrimeCanjePromo {
  color: #fff !important;
  background: #523178 !important;
  padding: 12px 0 !important;
  max-width: none;
  width: 75% !important;
  height: 50px !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  line-height: 20px;
  font-weight: 600;
  border-radius: 4px !important;
  cursor: pointer;
  max-width:80%;
  line-height: 8.5px;
  display: inline-block;
  align-items: center;
  text-align: center;
  @include respond-to(576px){
    height: 42px !important;
    width: 326px !important;
    max-width: 100% !important;
  }

}
#nroDocumentoCP {
  max-width: none;
  width: 280px;
  // border: 1.07778px solid #8A8A8A;
}
#nroDocumentoCP2{
  max-width: none;
  width: 290px;
  // border: 1.07778px solid #8A8A8A;
}
#nroDocumento{
  max-width: none;
  width: 100%;
}
.boxSelected {
  background: #523178;
}
.textSelected {
  color: #fff;
}
.textUnselected {
  color: #523178;
}
.boxDni, .boxDniCP, .boxDniCP2 {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #523178;
  cursor: pointer;
  @include respond-to(576px){
    line-height: 0.9;
    height: 28px;
  }
}
.boxCe, .boxCeCP, .boxCeCP2 {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #523178;
  cursor: pointer;
  @include respond-to(576px){
    line-height: 0.9;
    height: 28px;
  }

}
.btnConsultarPrimeCanjePromo:hover {
  background: #5E388A;

}
.tituloitemCategoria {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
.fontTextoPromoHome {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: $grey4;
}
.btnInscripcionTarjeta {
  color: #fff !important;
  background: #523178;
  background-color: #523178 !important;
  padding: 12px 0 !important;
  max-width: none;
  width: 158px !important;
  //margin: 15px 0 10px 0;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  display: inline-block !important;
  cursor: pointer;
  max-width:60% !important;
  margin-top: -7px !important;
  margin-left: 12px !important;
}
.btnInscripcionTarjeta:hover {
  background: #5E388A;
}

.btnInscripcion {
  color: #fff !important;
  background: #EE2737;
  padding: 16px 0;
  max-width: none;
  //width: 34%;
  //margin: 15px 0 10px 0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px !important;
  display: inline-block;
  cursor: pointer;
  max-width:60%;
}
.btnInscripcionAcumula {
  color: #fff !important;
  background: #EE2737 !important;
  padding: 12px 0 !important;
  // max-width: none;
  font-size: 16px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  border-radius: 4px !important;
  display: inline-block !important;
  cursor: pointer;
  // max-width:60%;
  width: 100% !important;
  @include respond-to(767px){
    min-width: 240px !important
  }
}
.btnSolTarjeta {
  color: #fff !important;
  background: #EE2737 !important;
  padding: 14px 0 !important;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 4px !important;
  display: inline-block !important;
  cursor: pointer;
  // max-width:72%;
  @include respond-to(767px){
    min-width: 295px !important
  }
}
.fontsubgrey4{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #737373;
  @include respond-to(767px){
    margin-left: -4px;
    margin-right: -17px;
  }
}
.fontsubgrey5{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #737373;
  @include respond-to(767px){
    margin-left: -4px;
    margin-right: -17px;
  }
}
.fontsubgrey6{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #404040 !important;
  @include respond-to(767px){
    margin-left: -4px;
    margin-right: -17px;
  }
}
.contentPoints{
  // position: absolute;
  // margin:40px;
  margin-top: 40px;
  // width: 514px;
  // height: 193px;
  // left: 118px;
  // top: 200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.119455);
  border-radius: 7px;
  @media (max-width: 575.98px) {
    // position: absolute;
    // width: 100%;
    // left: 0px;
  }
}

.fixMargenResponsiveCP {
  margin-right: 30%;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 138.97%;
  color: #404040 !important;
  padding-left: 44px;
  padding-right: 15px;
  @media (max-width: 575.98px) {
    padding-left: 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 138.97%;
    text-align: center;
  }
}
.contenedor_boxBeneficios {
  background: #fff;
  min-height: 200px;
  border-radius: 8px;
  overflow: hidden;
}
.contenedor_boxConsultar {
  background: #fff;
  min-height: 180px;
  border-radius: 8px;
  overflow: hidden;
}
.contenedor_boxFooter {
  background: #191919;
  min-height: 170px;
  border-radius: 8px;
  overflow: hidden;
}
.contenedorSegmentador{
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fcfcfc;
  font-family: $montserrat;
}

.contenedorRegistroOMP {
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fcfcfc;
  font-family: $montserrat;

  .mensaje-final-OMP{
    background: #FFFFFF;
    border: 1.0482px solid rgba(151, 153, 155, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 8px 20px #F2F4F9;
    border-radius: 8px;
    width: 600px;
    height: 510px;
    text-align: center;
  }

  //estilos del checkbox
  .form-datosPersonalesOMP{
    padding: 0;

    .form-check-input{
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -30px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }

      // Box hover
      &:hover + label:before {
        background: #523178;
      }

      // Box checked
      &:checked + label:before {
        background: #523178;
        border: 1px solid #523178;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
      }
    }

    label a{
      text-decoration: underline;
      color: #8C4799;
    }
  }


}


//REGISTRO OMP
.contenedorRegistroOMP {
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fcfcfc;
  font-family: $montserrat;

  .mensaje-final-OMP{
    background: #FFFFFF;
    border: 1.0482px solid rgba(151, 153, 155, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 8px 20px #F2F4F9;
    border-radius: 8px;
    width: 600px;
    height: 510px;
    text-align: center;
  }

  //estilos del checkbox
  .form-tiposDocumento{
    padding: 0;
    width: 100%;

    .form-radio-input{
      //position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -42px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 5em/5em;
      }

      // Box hover
      &:hover + label:before {
        background: #8C4799;
      }

      // Box checked
      &:checked + label:before {
        background: #8C4799;
        border: 1px solid #8C4799;
        border-radius: 5em/5em;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 72px;
        right: 136px;
        top: 3px;
        bottom: 2px;
        border-radius: 5em/5em;
        border: 2px solid #ffff;
      }
    }

    .form-radioD-input{
      //position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -42px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 5em/5em;
      }

      // Box hover
      &:hover + label:before {
        background: #8C4799;
      }

      // Box checked
      &:checked + label:before {
        background: #8C4799;
        border: 1px solid #8C4799;
        border-radius: 5em/5em;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: -10px;
        right: 36px;
        top: 3px;
        bottom: 2px;
        border-radius: 5em/5em;
        border: 2px solid #ffff;
      }
    }
    label a{
      text-decoration: underline;
      color: #8C4799;
    }

  }

  .form-datosPersonalesPromociones{
    padding: 0;

    .form-check-input{
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -30px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }

      // Box hover
      &:hover + label:before {
        background: #8C4799;
      }

      // Box checked
      &:checked + label:before {
        background: #8C4799;
        border: 1px solid #8C4799;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
      }
    }

    label a{
      text-decoration: underline;
      color: #8C4799;
    }
  }
}
//PROMOCIONES
.contenedorRegistroPromociones {
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fcfcfc;
  font-family: $montserrat;

  .mensaje-final-OMP{
    background: #FFFFFF;
    border: 1.0482px solid rgba(151, 153, 155, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 8px 20px #F2F4F9;
    border-radius: 8px;
    width: 600px;
    height: 510px;
    text-align: center;
  }

  //estilos del checkbox
  .form-tiposDocumento{
    padding: 0;
    width: 100%;

    .form-radio-input{
      //position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -42px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 5em/5em;
      }

      // Box hover
      &:hover + label:before {
        background: #523178;
      }

      // Box checked
      &:checked + label:before {
        background: #523178;
        border: 1px solid #523178;
        border-radius: 5em/5em;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 72px;
        right: 136px;
        top: 3px;
        bottom: 2px;
        border-radius: 5em/5em;
        border: 2px solid #ffff;
      }
    }

    .form-radioD-input{
      //position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -42px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 5em/5em;
      }

      // Box hover
      &:hover + label:before {
        background: #523178;
      }

      // Box checked
      &:checked + label:before {
        background: #523178;
        border: 1px solid #523178;
        border-radius: 5em/5em;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: -10px;
        right: 36px;
        top: 3px;
        bottom: 2px;
        border-radius: 5em/5em;
        border: 2px solid #ffff;
      }
    }
    label a{
      text-decoration: underline;
      color: #8C4799;
    }

  }

  .form-datosPersonalesPromociones{
    padding: 0;

    .form-check-input{
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 30px;
      }

      // Box.
      & + label:before {
        content: '';
        margin-left: -30px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }

      // Box hover
      &:hover + label:before {
        background: #523178;
      }

      // Box checked
      &:checked + label:before {
        background: #523178;
        border: 1px solid #523178;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
      }
    }

    label a{
      text-decoration: underline;
      color: #8C4799;
    }
  }
}

.itemInstruccionOMP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF !important;
  padding-left: 60px;
  margin-bottom: 20px;
  padding-right: 8px;
}
.divBannerIzquierdoOMP {
  position: relative;
  max-width: 520px;
  width: 520px;
  // height: 907px;
  left: 0px;
  top: 0px;
  //background: linear-gradient(180deg, #7E4ABA 0%, #462A66 100%);

  background-color:#503379;
  bottom: 0px;
  //margin-top: 40px;
  padding-top: 40px;
  // padding-left: 40px;
}
.divBannerIzquierdoPRO {
  position: relative;
  max-width: 520px;
  width: 520px;
  // max-width: 100%;
  // width: 100%;
  // height: 907px;
  // left: 0px;
  // top: 0px;
  //background: linear-gradient(180deg, #7E4ABA 0%, #462A66 100%);
  background-color: #523379;
  //bottom: 0px;
  //padding-top: 50px;
  // padding-left: 40px;

}

.imgPromocionLateral{
  width: 520px;
  margin-left: -15px;
}
.imgPromocionLateralMovil{
  width: 100%;
  height: 286px;
  //margin-left: -15px;
}
.imgPromocionLateralMovil2{
  width: 100%;
  padding-top: 40px;
  //height: 100%;
  //margin-left: -15px;
}
.fixMarginImgInstruccionOMP {
  padding-top: 10px;
}
.divBannerCentroOMP {
  // left: 0px;
  // top: 0px;
  padding-top: 120px;
  text-align: center;
}
.btnRegistroOMP {
  background: #523178;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #FAFAFA;
  height: 50px;
  width: 165px;
}

.btnRegistroOMP:hover {
  color: #FAFAFA;
}
.btnInicioOMP:hover {
  color: #FAFAFA;
}

.textoCheckOMP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;

}

.fondoimgBannerLateralOMP {
  width: 100%;
  height: 450px;
  background-image: url("/assets/img/ripley_puntos/maju_omp.png");
  background-repeat:no-repeat;
  background-position:center bottom;
  position: absolute;
  bottom: 0px;
}


.divBannerCentroOMPConfirm{
   margin-top: 150px;
}
.formContaintOMP {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.formConfirmContaintOMP {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
.titleRegistroOMP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold !important;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #523178 !important;
}
.descripcionRegistroOMP {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #404040 !important;
  margin-top: 15px;
  margin-bottom: 20px;
}
.rowOMP {
  margin-top: 10px;
  margin-bottom: 10px;
}
.inputRegistroOMP {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
}
.titleConfirmOMP{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold !important;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #523178 !important;
}
.subtitleConfirmOMP{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #404040 !important;
}
.MargintextoCheckOMP{
  // margin-left: 112px;
  padding-left: 112px !important;
}
.parrofoConfirmOMP{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  color: #404040 !important;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: -15px;
}
.btnInicioOMP {
  background: #523178;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #FAFAFA;
  height: 55px;
}
.decotation-cp{
  text-decoration-line: underline;
}
.contenedor-inscripcion{
  height: auto;
  width: 100%;
  margin-top: 40px;
}
.box-puntos1{
  width: 100%;
  background: #FFFFFF;;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 15px;
  border: 2px solid #FACF4D;
  box-shadow: 4px 8px 20px #ECECEC;
  border-radius: 7.98637px;
  min-width: 282px;
  min-height: 147px;
  // @media (max-width: 575.98px) {
  //   min-width: 273px;
  //   min-height: 143px;
  //   max-width: 273px;
  //   max-height: 143px;
  // }
}
.box-puntos1_{
  width: 100%;
  max-width: 282px;

}
.fixMarginLeftbox-puntos1_{
  margin-left: -17px;
}

.box-puntos2{
  width: 100%;
  background: #FFFFFF;;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 15px;
  // box-shadow: 4px 8px 20px #ECECEC;
  // border: 0.998296px solid #7747AE;
  border: 2px solid #7747AE;
  box-shadow: 4px 8px 20px #ECECEC;
  border-radius: 7.98637px;
  min-width: 282px;
  min-height: 147px;
  @media (max-width: 575.98px) {
    width: 100%;

  }
}
.tooltip-inner.tooltip-bottom{
  // background-color: #8C4799 !important;
  background: #FFFFFF !important;
  font-weight: 400;
  font-size: 12px;
  color: #444444;
}

.tooltip-inner {
  background-color: #8C4799 !important;
  opacity: 100%;
  color: #ffffff ;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  min-width: 296px;
  text-align: justify !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  border-top-color:#8C4799;
  display: flex !important;
  justify-content: center !important;
  @media (max-width: 575.98px) {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    padding-left: 14px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    max-width: 242px !important;
    // max-height: 7px !important;
    margin-left: 10% !important;
  }
}

.tooltip-inner.tooltip-bottom::after{
  border-color: #0000 #0000 white !important;
}


.tooltip-inner.tooltip-top::after{
  border-color: #8C4799 #0000 #0000 !important;
}

.contenedorSegmentador{

  .fuente1{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #404040;
  }
  .fuente2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #8C4799;
  }
  .fuente3{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #404040;
  }
  .fuente4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #404040;
    width: 242px;
    @media (max-width: 575.98px) {

    }
  }
  .fuente4b{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #404040;
    width: 288px;
    @media (max-width: 575.98px) {

    }
  }
  .fuente5{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #6A6A6A;

    position: relative;
    left: -33px;
    top: 22px;
    @media (max-width: 575.98px) {
      left: 0px !important;
      top: -7px !important;
    }
  }
  .fuente6{
    @media (max-width: 575.98px) {
      font-style: normal !important;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
  .fuente6Bold{
    font-weight: 600 !important;
  }
  .fuente7{
    @media (max-width: 575.98px) {
      font-family: Montserrat !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
  .fuente8{
    @media (max-width: 575.98px) {
      font-family: Montserrat;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      color: #523178;
      margin-right: -20px;
    }
  }
  .fuente9{
    @media (max-width: 575.98px) {
      font-family: Montserrat !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 15px !important;
      line-height: 18px !important;

    }
  }
  .box-puntos2purple {
    background: url("/assets/img/svg/i-start-rptgo-purple.svg") center right no-repeat;
  }
  .box-puntos2yellow {
    background: url("/assets/img/svg/i-start-rptgo-yellow.svg") center right no-repeat;
  }
}

.box-puntos2purpleOut {
  background: url("/assets/img/svg/i-start-rptgo-purple.svg") center right no-repeat;
  @media (max-width: 575.98px) {
    min-width: 278px !important;
    width: 80% !important;
  }
}

.contenedor-segmentador{
  height: auto;
  width: 100%;
  margin-top: 40px;
}
.contenido-img{
  width: 100%;
  height: 100%;
}
.contenido-img img {
  width: 100%;
  height: 100%;
}
.img-height{
  height: 100%;
}

.seccionRipleyPuntos{
  height: auto;
  width: 100%;
  margin-top: 80px !important;

  /*
  W.Levita - ahora
  margin-top: 18px;

  W.Levita - antes
  margin-top: 90px;
  */
}

.slider-RipleyPuntos{
  margin-top: 60px !important;
}

.rp-progress{
  margin-left: 26%;
  margin-right: 8%;
}
.rpg-base{
  position: relative;
  background-color: #462a66;
}
.categoria{
  margin-left: 16%;
  margin-right: 21%;
  text-align: center;
}
.rpg-img-xs{
  margin: auto;
  height: 157px;
}
.rpg-progress{
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  //background-color: #e9ecef;
  border-radius: .25rem;
}
.rpg-progress-bar{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #404040;
  text-align: right;
  white-space: nowrap;
  transition: width .6s ease;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  font-family: Montserrat;
  font-weight: 500;
}
.sec-progress{
  //width: 70%;
  background-color: #894590 !important;
  height: 0.7rem !important;
}
.t-float{
  position: relative;
  display: inline-block;
  text-align: center;
}
.tflot{
  position: absolute;
  top: 10px;
  left: 10px;
}
.t-float-banner{
  position: absolute;
  top: 85px;
  left: 7%;
}
.bottomInfo{
  position: absolute;
  bottom: 0px;
 }

.fontFromRptoToWebPublica{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  margin-left: 302px;
  margin-top: 182px;
  @media (max-width: 575.98px) {

  }
}
.fontFromRptoToWebPublica:hover {
  color: #FFFFFF;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .seccionRipleyPuntos{
    /*margin-top: 18px !important; */
    margin-top: 90px !important;
  }
  .rp-progress{
    margin-left: 11%;
    margin-right: 8%;
  }
  .categoria{
    margin-left: 0%;
    margin-right: 0%;
    text-align: left;
  }
  .anchoTerCondBenefPromociones{
    display: none;
  }
  .CondicionesdePromociones{
    display: none;
  }
  .resposivePromotion{
    margin-bottom: -73px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .seccionRipleyPuntos{
    /*margin-top: 18px !important;*/
    margin-top: 90px !important;
  }
  .rp-progress{
    margin-left: 15%;
    margin-right: 8%;
  }
  .anchoTerCondBenefPromociones{
    display: none;
  }
  .CondicionesdePromociones{
    display: none;
  }
  .resposivePromotion{
    margin-bottom: -73px;
  }
 }

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .seccionRipleyPuntos{
    /*margin-top: 18px !important;*/
    margin-top: 90px !important;
  }
 }

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {

 }
 @media (min-width: 1200px) {

}
.contenedorAunSinTarjeta{
  margin-top: 30px;
  margin-bottom: 45px;
  .textMobile{
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
  }
}

.preguntasFrecuentesRptoGo {
  background-color: #FAFAFA;
}
.pregFrecTitulo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $grey4;
}

.colorItemAcordionRptoGo{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: $blue1;
}
#listaItemTutorialRptoGo{
  margin-top: 23px;
  margin-left: -15px;
  margin-right: 14%;
  list-style: none;
}

.espacioItemColRptoGo{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #404040;
}
.fixt7movil {
  margin-top: -23px;
}
.t7movil {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 25px !important;
  line-height: 29px !important;
  text-align: center !important;
}
.inputRegistroOMP::-webkit-input-placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #E6E6E6 !important;
}
.fixResponsiveTitleSegmentacion {
  color: #FFFFFF !important;
  margin-left: 7%;
  margin-top: -32%;
  padding-bottom: 23%;
  text-align: left !important;
}
// .fixCustomImg {

// }
//categorias
.contenedorCategorias{
  margin-top: 0px;
}
.divItemCategoria {
  max-width: 284px;
  height: 114px !important;
  height: 100%;
  width: 100%;
  background: #5E388A;
  border: 1.0482px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 8px;
}
.divItemCategoriaDescripcion {
  max-width: 284px;
  width: 100%;
  padding-bottom: 100px;
}
.divCategoriaLeft {
  margin-left: 30%;
}
.divCategoriaRight {
  margin-left: 10%;
}
.itemmoneyCategoria {
  margin-top: -30px;
  padding-left: 30px;
}
.itemdescripcionmoneyCategoria{
  margin-top: -5px;
  padding-left: 30px;
}
.boxBeneficioCategoria {
  max-width: 950px;
  width: 100%;
  height: 280px;
  background: #FFFFFF;
  border: 1.0482px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 8px;
  margin: auto;
  &.bordertop{
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.borderbottom{
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.boxBeneficioCategoriaEnd {
  max-width: 950px;
  width: 100%;
  height: 226px;
  background: #6B409C;
  border: 1.0482px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 8px;
  margin: auto;
}

.boxtitleCategorias {
  float: right;
  margin-right: 108px;
}
.lineacategoriabeneficios {
  margin-right: -108px;
  margin-left: -88px;
}
.boxItemCategoriaBeneficio {
  text-align: center;
  margin-left: 23%;
  margin-right: 23%;
  margin-top: 10px;
}

.boxItemCategoriaBeneficio2Col {
  text-align: center;
  margin-left: 21%;
  margin-right: 21%;
  margin-top: 10px;
}

.boxItemCategoriaBeneficio1Col {
  text-align: center;
  // margin-left: 23%;
  // margin-right: 23%;
  margin-top: 10px;
}

.boxItemCategoriaBeneficio1ColYelloy {
  text-align: center;
  margin-top: 10px;
}

.itemLeftBeneCat{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 145%;
  text-align: center !important;
  color: $darkPurple3 !important;
}
.titleitemLeftBeneCat {
  color: $darkPurple3 !important;
  margin-top: 10px;
}
.titleitem2ColBeneCat {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 38px;
  line-height: 145%;
  text-align: center !important;
}
.titleitem2ColBeneCatYelloy {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 130%;
  // display: flex;
  align-items: center;
  text-align: center  !important;;
  color:#F9C935 !important;
}


.subtitleitemLeftBeneCat {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 145%;
  text-align: center !important;
  margin-bottom: 10px;
}
.item2colBeneCat{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 145%;
  text-align: center !important;
}
.itemRespuestaPreguntaFrecuente {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.fixAltoBoxBeneficios {
  height: 225px;
}

.CondicionesdeBeneficios{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px !important;
  color: $purple4;
}

.CondicionesdeBeneficios:hover{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px !important;
  color: $purple4;
}

.CondicionesdeBeneficiosReglamento{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px !important;
  text-decoration-line: underline;
  color: $purple4;
}

.CondicionesdeBeneficiosReglamento:hover{
  color: $purple4;
}


.CondicionesdePromociones{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px !important;
  color: $purple4;
}

.CondicionesdePromociones:hover{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px !important;
  color: $purple4;
}
.fixwidthRespuestaPreguntaFrecuente {
  max-width: 100%;
  width: 100%;
}

.linkPurple4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: $purple4;
  text-decoration: underline;
}
.linkPurple4:hover{
   color:$purple4;
}
.linkPurple4:before{
  color:$purple4;
}
.fixRespuestaPrestamo {
  margin-right: -140px !important;
}
.noIncluyegift {
  color: $grey5 !important;
}
.alignImgLeft {
  float: left;
  padding-right: 12px;

}
.fontTodoPuedeCanjear{
  width: 110%;
}
.fontQueSonRipleyPuntosGo {
  width: 75%;
}
.alignImgLeftText {
  display: flex;
  align-items: center;
}
.fixPaddingLeftImgPreguntaFrecuentaCat{
  padding-left: 30px;
}
.fixFooterCategorias{
  margin-bottom: -50px;
}

.itemTerminosCondicionesBeneficiosCategorias {
  font-family: Montserrat;
  font-style: normal;
  font-size: 12px;
  line-height: 145%;
  text-align: justify;
}
.itemTerminosCondicionesTitulo {
  font-family: Montserrat;
  font-style: normal;
  font-size: 20px;
  line-height: 145%;
  text-align: justify;
}
.anchoTerCondBenefCategoria {
  width: 1159px;
}
.anchoTerCondBenefPromociones {
  width: 1159px;
}
.tituloCategoria_rpgo{
  font-weight: 600;font-size: 32px;line-height: 28px;
}
.tituloPromociones_rpgo{
  font-weight: 600;font-size: 32px;line-height: 28px;
  margin-bottom: -20px;
}
.titleBen_Categ {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
}
.subtitleBen_Categ {
  color:#737373 !important;
  font-size: 18px !important;
}
.subtitleBen_CategHome {
  color:#737373 !important;
  font-size: 11px !important;
}
.tabBeneficioCategoria {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 22px;
}
.imgTabBeneCat{
  height: 22px !important;
}
.modalTCB {
  margin-bottom: 80px;
  margin-top: 100px;
  margin-left: 10px;
  margin-right: 10px;
  background: #FFFFFF;
  // border: 1.0482px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}
.modalPC {
  // margin-bottom: 80px;
  margin-top: 23px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}
// .SpanFlotanteTCB {

// }
.divCerrarFlotanteSpanMovilTCB {
  width: 20px;
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 8999;
}

.titleModalTermCondBenef{
  color: $purple4;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.itemRequisitosCanjearPuntos{
  margin-left: -60px;
}

.titleVitrina{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  @media (min-width: 576px) and (max-width: 767.98px) {
    line-height: 28px !important; 
    font-size: 24px !important; 
    white-space: pre;
    margin-top: -38%;
  }
}
.sub-titleVitrina{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px  !important;
}
.altoImgVitrinaCategoria {
  height: 600px;
}

.btnVerReglamentoRPTGO{
  color: $darkPurple3;
  align-items: center;
  text-align: center;
  width: 160px;
  height: 32px;
  border: 1px solid $darkPurple3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 46px;
  margin-top: 30px;
  padding-left: 3%;

}
.btnVerReglamentoRPTGO:hover{
    text-decoration: none;
    background: $darkPurple3;
    border: 1px solid $darkPurple3;
    box-sizing: border-box;
    border-radius: 4px;
    color : #FAFAFA;
}
.fuenteVerReglamentoRPTGO {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
}
.divReCaptcha {
  position: absolute;
  z-index: 99984211;
}
.btn_SubMenuRPGO {
  font-family: Montserrat;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 0.2em;
  display: flex;
  align-items: center;
  color: $grey4;
}
.btn_SubMenuRPGO:hover {
  background: $darkPurple3 !important;
  color: #FAFAFA !important;
}
.fixwidthdropdown-menuCanjesPromociones {
  width: 188px !important;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  left: auto !important;
}



.pageDetails {
  &_CanjePromocion{
    #nav-tabContentCanjePromocion .s7{
      @include respond-to(575px){
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }
    #nav-tabContentCanjePromocion .s6{
      @include respond-to(575px){
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
      }
    }
    #nav-tabContentCanjePromocion .blue1{
      @include respond-to(575px){
        font-style: normal;
        font-size: 16px;
        line-height: 138.97%;
        margin-left: -18px;
        margin-right: -18px;
        a{
          font-size: 16px;
        line-height: 138.97%;
        }
      }
    }
    #nav-tabContentCanjePromocion .purpleC{
      @include respond-to(575px){
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold !important;
        font-size: 18px;
        line-height: 22px !important;
        margin-bottom: 5px;
        margin-left: -44px;
      }
    }
    #nav-tabContentCanjePromocion .hr-rpg-greyC{
      @include respond-to(575px){
        margin-left: -44px;
        width: 270px !important;
      }
    }
    #nav-tab{
      @include respond-to(575px){
        margin-top: -28px;
        margin-bottom: -8px;
      }
      @media (min-width: 576px) and (max-width: 767.98px) {
        margin-top: -28px;
        flex-wrap:nowrap;
      }
      @media (min-width: 768px) and (max-width: 991.98px) {
        margin-top: 30px;
        flex-wrap:nowrap;
      }



      // @include respond-to(767px){
      //   margin-left: -50px;
      //   margin-top: -28px;
      //   flex-wrap:nowrap;
      // }
      // @include respond-to(991px){
      //   margin-left: -50px;
      //   margin-top: -28px;
      //   flex-wrap:nowrap;
      // }
    }
    #nav-tab.nav-tabs .nav-link.active {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 138.97%;
      text-align: center;
      color: $purple4;
      border-bottom: 3px solid $purple4;
    }

    #nav-tab .nav-link{
      min-width: 90px;
      padding: 20px 40px 4px 40px;
      margin-left: 30px;
      border:0px;
      @include respond-to(992px){
        font-size: 18px !important;
        padding: 10px 10px 10px 10px !important;
        margin-left: 16px !important;
      }
      @include respond-to(1199px){
        font-size: 18px !important;
        padding: 10px 10px 10px 10px !important;
        margin-left: 16px !important;
      }
    }

    #nav-tab.nav-tabs .nav-link {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 138.97%;
      text-align: center;
      color: $grey5;
      // @include respond-to(575px){
      //   display: inline;
      //   padding-right: 2px !important;
      //   padding-left: 2px !important;
      //   margin-left: 12px !important;
      //   text-align: center !important;
      // }
      @include respond-to(1199px){
        display: inline;
        padding-right: 2px !important;
        padding-left: 2px !important;
        margin-left: 12px !important;
        text-align: center !important;
      }

    }
    #nav-tab.nav-tabs a:hover {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 138.97%;
      text-align: center;
      color: $purple4;
      border-bottom: 3px solid $purple4;
    }




  }
}

.centertabPage {
  margin-left: 5%;
  margin-right: 5%;
}
.blue1 {
  color: $blue1;
}
.boxCanjePromo {
  width: 446px;
  height: 246px;
  background: #FEFEFE;
  border: 1px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
}

.ptitleBoxCanjePromo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 138.97%;
  color: $grey4;
}

.titleBoxCanjePromoPrice{
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 138.97%;
  display: flex;
  align-items: center;
  color: $darkPurple3;
  padding-top: 18px;
}
.subtitleBoxCanjePromo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px !important;
  line-height: 138.97%;
  margin-top: 15px;
  font-size: 14px !important;
}
.imgLogoCanjePromo {
  margin-top: -10px;
}
.imgTarjetaCanjePromo {
  // margin-top: -70px;
  // margin-left: 40px;
  margin-top: -105px;
  margin-left: 185px;
}
.textAgotadoFix{
  margin-bottom: -25%;
  font-size: 30px;
  @include respond-to(575px) {
    margin-bottom: -33%;
    font-size: 29px;
  }

}
.fixIzquierdaMargenCanjePromo {
  margin-left: 9%;
}
.fixDerechaMargenCanjePromo {
  margin-right: 9%;
}
.contenedorClientPrimeCanjePromo{
  min-height: 207px;
  height: 100%;
  // background: linear-gradient(180deg, rgba(240, 238, 255, 0) 0%, #F0EEFF 36.98%);
  background-color: #FAFAFA;
}
.fontDescuebrePuntosGo{
  font-size: 22px !important;
  padding-left: 44px;
  @media (max-width: 575.98px) {
    padding-left: 0px;
    text-align: center !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}
.preguntasFrecuentesCanjePromo {
  background-color: #FFF;
}
.containerImgTriplicaCanjePromo {
  width: 760px;
  margin: auto;
}
.hr-rpg-greyC{
   width: 450px !important;
   border:none !important;
   border: 0.5px solid #D9D9D9 !important;
   float: left;
}
.boxContainerExpeSuper {
  width: 350px;
  // width: 378px;
  height: 267px;
}

.boxContainerExpeSuperExperiencia {
  width: 350px;
  height: 267px;
}
.boxContainerExpeSuperTop {
  // width: 377px;
  width: 349px;
  height: 173px;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}

.boxContainerExpeSuperTopExperiencia {
  width: 350px;
  height: 173px;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}
.boxContainerExpeSuperTopChild {
  width: 130px;
  height: 82px;
  background: #6B409C;
  border-radius: 4px;
  text-align: center;
}
.boxContainerExpeSuperTopChildSC {
  width: 130px;
  height: 99px;
  background: #6B409C;
  border-radius: 4px;
  text-align: center;
}

.FontboxContainerExpeSuperTopChildSC {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: -7px;
  margin-bottom: 6px;
}

.FontboxContainerExpeSuperTopChild {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  padding-top: 5px;
}
.boxContainerExpeSuperBottom {
  // width: 377px;
  width: 350px;
  height: 93px;
  background: #FACF4D;
  border-radius: 0px 0px 4px 4px;
}

.boxContainerPromoTriplica {
  width: 382px;
  height: 247px;
  background: #FEFEFE;
  border-top: 1px solid rgba(151, 153, 155, 0.15);
  border-left: 1px solid rgba(151, 153, 155, 0.15);
  border-right: 1px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 8px 8px 0px 0px;
}
.boxContainerTriplica {
  width: 335px !important;
  height: 251px !important;
  position: relative;
  left: 50%;
  margin-left: -167.5px !important;
  background: #FEFEFE;
  border: 1px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 8px;
}
.boxContainerPromotionTriplicaBottom {
  width: 382px;
  height: 109px;
  background: #FEFEFE;
  border-bottom: 1px solid rgba(151, 153, 155, 0.15);
  border-left: 1px solid rgba(151, 153, 155, 0.15);
  border-right: 1px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px #F2F4F9;
  border-radius: 0px 0px 8px 8px;

}
.boxContainerExpeSuperBottomExperiencia {
  width: 350px;
  height: 93px;
  background: #EEEEEE;
  border-radius: 0px 0px 4px 4px;
  border-bottom: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}
.titleboxExpeSuper{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
}
.fixFilaContainer{
  margin-left: -68px;
  margin-right: -68px;
}
.Col1_Expe{
  float: left;
}
.Col2_Expe{
  margin:auto;
}
.Col3_Expe{
  float: right;
}

.pagelinkItem {
    margin-right: 25px;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid $grey5;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    // display: flex;
    align-items: center;
    text-align: center;
    color: $grey5;
    padding-top: 15%;
}
.pagelinkItem:hover {
  color: #FFFFFF !important;
  background: $purple4;
  border: 1px solid $purple4;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px !important;
}
.page-item.active .pagelinkItem {
    z-index: 1;
    color: #fff !important;
    background: $purple4 !important;
    border: 1px solid $purple4 !important;
}
#slide-tabDetallePaginacion{
  .carousel-inner .carousel-item.active{
    display: flex;
  }

}

.col-title-segmento-millas {
  margin-top: 30px;
  margin-left: 180px;
}

.col-title-segmento {
  width: 286px;
  margin-left: 60px;
}
.col-subtitle-segmento {
  max-width: 307px;
  width: 277px;
  margin-left: 240px;
  margin-top: 30px;
}
.col-box-segmento {
  margin-left: -30px;
  margin-top: 25px;
}
.formConsultaCP{
  width: 280px;
  margin: auto;
  margin-left: 90px;
  // @media (max-width: 575.98px) {
  //    //margin-left: 20px !important;
  // }
}
.formConsultaCPLine{
  width: 35px;
  margin: auto;
  margin-top: -20px;
  margin-bottom: 10px;
}
.fontDescubre{
  font-size: 18px;
  margin-bottom: 15px;
}
.cssWidthRP{
  width: 295px;
  @include respond-to(576px) {
    width: 260px !important;
  }
}

.FontboxContainerExpeSuperBottomChild{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  margin-top: -5px;
  // color:$purple4;

}
.cssInscribiteAqui{
  text-decoration: underline;;
  font-weight: 500 !important;
  line-height: 17px !important;
}
.cssInscribiteAqui:hover{
  color:$purple4;
}
.cssNoConsult {
  margin: auto;
  width: 54%;
}
.footerVerReglamento {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-decoration: underline;
}
.footerVerReglamento:hover{
  color:$purple4;
}
.cont-footer-new0 {
  background-color: #F2F2F2;
  min-height: 62px;
  // height: 100%;
}
.styleFooterLineOne{
  padding-top: 19px;
}
.bef-img2{
  margin-top: -23px;
  margin-top: 0px;
}

.acumula_titleVitrina {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.acumula_sub-titleVitrina {
  @include respond-to(767px) {
    color: $white !important;
  }
}

.home-titleVitrina1 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-sub-titleVitrina1 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-titleVitrina2 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-sub-titleVitrina2 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-titleVitrina3 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-sub-titleVitrina3 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-titleVitrina4 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.home-sub-titleVitrina4 {
  @include respond-to(767px) {
    color: $white !important;
  }
}
.fixPaddingLeftExperiencia{
  padding-left: 55px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.logo-br{
  max-width: 150px;
}

.arrow-right{
  &.active{
    &:after{
      transform: rotate(180deg);
      opacity: .5;
    }
   }
  &:after{
     @extend .transition1;
     right: 3px;
   }
}
.itemVinhetaSegmentador {
  // margin-left: -10px;
  margin-top: -2px;
}

.CondicionesdeBeneficios {
  img {
    transform: rotate(0deg);
  }
  &.collapsed{
    img {
      transform: rotate(0deg);
    }
  }
}
.CondicionesdePromociones {
  img {
    transform: rotate(180deg);
  }
  &.collapsed{
    img {
      transform: rotate(0deg);
    }
  }
}
.divLogoRPGO {
  height: 45px;
}
.divImgLinkControlCarousel {
  width: 30px;
  height: 30px;
  z-index: 1197777;
}

.contentPageBeneficioCategoria{
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
}
.seccionSilverBeneficio, .divseccionGoldBeneficio{
  position: absolute;
}

.boxVerPromocionesNoAfiliadas {
  max-width: 340px;
  margin: auto;
}
.btn-boxVerPromocionesNoAfiliadas{
  width: 100%;
  background-image: url(/assets/img/svg/arrow-pink-ver-promo.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 98% 50% !important;
  text-align: left;
  background: #FFFFFF;
  height: 103px;
  padding-left: 9%;
  border: 1.0482px solid rgba(151,153,155,0.15);
  box-sizing: border-box;
  box-shadow: 0 8px 20px #f2f4f9;
  outline: 0 !important;
  border-radius: 8px;
}
.btn-boxVerPromocionesNoAfiliadas:hover{
  transition-property: box-shadow;
  transition-duration: 500ms;
  // transition-delay: 500ms;
  box-shadow: 20px 20px 20px #E4E5E8;
}

.p1VerMasPromociones {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 138.97%;
  color: $blue1;
  padding-bottom: 3px;
}
.p2VerMasPromociones {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 138.97%;
  color:$grey5
}
.linkVerMasPromociones {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 138.97%;
  text-decoration-line: underline;
  color: $purple4
}
.linkVerMasPromociones:hover {
  color: $purple4
}
.select-tab-markWhite {
  background-color: white;
  border: 1.5px solid white;
  max-width: 95%;
  border-radius: 20%;
}

// #menu {
//   background-image: url("../img/menu_fixleft-float.png");
//   background-repeat:no-repeat;
//   background-position: center right;
// }
.box_NoPromo{
  max-width: 482px;
  height: 110px;
  border: 1px solid rgba(151, 153, 155, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
    align-items: center;
    text-align: center;
  p{
    font-size: 16px;
line-height: 138.97%;
color: #737373;
  }
}

.fixNewDesignVerAcumular{
  padding-left: 0px;
  @media (max-width: 575.98px) {
    width: 360px;
    // margin-left: -10px;
    // font-size: 15px !important;
  }
}

.fontVerAcumuladoRptogo{
  font-size: 15px !important;
}

.fontAcumulaConDocumento{
  font-size: 30px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 132.87%;
  @media (max-width: 575.98px) {
    width: 100%;
  }
}
.fontSacaleProvecho{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 138.97%;
  color: #404040;
  @media (max-width: 575.98px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 138.97%;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.fixTextCenterRptogo{
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.centerMarginSegmentadorPromo{
  @media (max-width: 575.98px) {
    padding-left: 2%;
    padding-right: 2%;
  }
}


.btnConsultarPrimeCategoria {
  color: #fff !important;
  background: #523178 !important;
  padding: 12px 0 !important;
  max-width: none;
  width: 80%!important;
  //margin: 15px 0 10px 0;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  display: inline-block !important;
  cursor: pointer;
  max-width:80%!important;
  font-family: Montserrat;
}

.btnVolverCuenta {
  color: #fff !important;
  background: #523178;
  padding: 12px 0;
  width: 250px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px !important;
  display: inline-block;
  cursor: pointer;
  min-width:40%;
  font-family: Montserrat;
}

/********* William Levita - Begin *******/
.boxContainerLatam {
  border-top: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  text-align: right;
}

.boxLatamBorder {
  border-bottom: 1px solid #D9D9D9;
}

.boxLatamColor {
  background: #6b409c;
  border-bottom: 1px solid #878383;
  padding-top: 40px;
}

.titleBoxContainerLatam {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 138.97%;
  align-items: center;
  color: #523178;
  padding-top: 25px;
}

.titleBoxContainerLatamColorA {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 138.97%;
  align-items: center;
  color: #EEEEEE;
}

.titleBoxContainerLatamColorB {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 138.97%;
  align-items: center;
  color: #FFB71B;
}

.textBancaInternet{
  font-style: normal;
  font-size: 19px !important;
  text-decoration-line: underline;
}

.textBancaInternet2{
  color: #404040 !important;
  font-style: normal;
  font-size: 13px !important;
  text-decoration-line: underline;
}

.displayblock{
  display: block;
}

.displaynone{
  display: none;
}

/********* William Levita - End *******/
