// colores
$grey1: #4c4c4c; //--> lvl normal txt
$grey2: #7b7e83; //--> lvl normal txt
$grey3: #97999b;
$grey4: #404040;
$grey5: #737373;
$lightGrey1: #f9f9f9; //--> lvl normal bg
$lightGrey2: #efefef;
$lightGrey3:#7b7e83;
$lightGrey4:#E6E7E8;
//$yellow1: #FFB81C; //--> lvl normal bg +txt
$yellow1: #f2ad4b; //--> lvl normal bg +txt
$yellow2: #feb957;
$gold: #F8C21C;
$purple4: #8C4799; //--> lvl normal bg + txt + link
$purple1: #894590; //--> lvl normal bg + txt + link
$purple2: #893492;
$purple3: #964d9e;
$darkPurple3: #523178; //--> lvl normal bg + txt
$darkPurple1: #4f2d7f; //--> lvl normal bg + txt
$darkPurple2: #361860;
$blue1: #462A66;
//$red1: #e22d36; //--> lvl normal bg + txt
$red1: #EE2737; //--> lvl normal bg + txt
$red2: #f8414b;
$contingencia: #ffffd1; //--> lvl normal txt
$white: #ffffff;
// fin colores

//medias
$breakpoints: (
        'xs': (max-width: 576px),
        'sm': (min-width: 577px) and (max-width: 768px),
        'md': (min-width: 769px) and (max-width: 992px),
        'lg': (min-width: 993px),
);
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);
  @if $raw-query {
    $query: if(
                    type-of($raw-query) == 'string',
                    unquote($raw-query),
                    inspect($raw-query)
    );
    @media #{$query} {
      @content;
    }
  } @else {
    @media (max-width: $breakpoint) {
      @content;
    };
  }
}
$montserrat: 'Montserrat', sans-serif;
//fin medias