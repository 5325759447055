 //header
 .disable-scroll{
  position: fixed!important;
  overflow: hidden;

  // overflow:hidden;
  //-webkit-overflow-scrolling:touch;
}
.ripley-header{
  background: $lightGrey1;
  position: fixed;
  top: 0;
  z-index: 9000;
}
.ripley-header-flotante{
  //top: 55px; // quitar dp comunicado
  top: 0;
  position: fixed;
  z-index: 9000;
  padding: 0px 60px 0px 60px;
}
.sites{
  .logo-ripley{
    height: 30px;
    width: 30px;
    padding: 8px;
    background:$lightGrey2 url('/assets/img/svg/logo-r.svg') center center no-repeat;
  }
  a{
    @include font-style($montserrat,600,12.5px,$grey3);
    padding: 6px 10px;
    max-height: 30px;
    @extend .transition1;
    &.active{
      background: #fff;
    }
    &:hover{
      background: $lightGrey2;
      text-decoration: none;
    }
  }
}
.cont-country{
  width: 100%;
  max-width: 150px;
  position: relative;
  span{
    @include font-style($montserrat,600,12.5px,$grey2);
  }
  .country{
    position: relative;
    @include font-style($montserrat,600,12.5px,$grey2);
    img{
      margin: 0 10px;
    }
    a,a:hover{
      @include font-style($montserrat,600,12.5px,$grey2);
      text-decoration: none;
    }
  }
  .country-dropdown{
    cursor: pointer;
    position: relative;
    @include font-style($montserrat,600,12.5px,$grey2);
    img{
      margin: 0 10px;
    }
    &:after{
      content: '';
      background: transparent url("/assets/img/svg/i-chevron-down.svg") center center no-repeat;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0;
      right: -20px;
    }
  }
  .country-options{
    background-color: $lightGrey1;
    position: absolute;
    bottom: -30px;
    left: 30px;
    padding: 5px 20px 5px 0;
    height: 30px;
    z-index: 200;
    @extend .transition1;
    &:hover{
      background-color: $lightGrey2;
    }
  }
}
.menu-br{
  height: 60px;
  background-color: #fff;
  position: relative;
  z-index: 100;
  //box-shadow:0 2px 12px 0 rgba(231,231,231, 0.5);
  //box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 15px rgba(82, 49, 120, 0.2);
  .logo-br{
    margin-left: 15px;
    max-width: 180px;
    img{
      // width: 100%;
    }
  }
  .btns{
    .btn-yellow,.btn-purple{
      @extend .transition1;
      height: 60px;
    }
    a{
      display: inline-block;
      @extend .transition1;
      text-align: center;
      vertical-align: center;
      padding:0 5px;
      @include font-style($montserrat,700,11.8px,#fff !important);
      text-transform: uppercase;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .btn-yellow{
    background-color: $yellow1;
    &:hover{
      background-color: $yellow2;
    }
  }
  .btn-purple{
    background-color: $purple1;
    &:hover{
      background-color: $purple2;
    }
    img{
      margin-left: 10px;
      margin-top: -4px;
    }
  }
}
.menu-br-flotante{
  height: 60px;
  background-color: #fff;
  position: relative;
  z-index: 100;
  //box-shadow:0 2px 12px 0 rgba(231,231,231, 0.5);
  //box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 15px rgba(82, 49, 120, 0.2);
  border: 1px solid rgba(82, 49, 120, 0.5);
  border-radius: 8px;
  .logo-br{
    margin-left: 15px;
    max-width: 180px;
    img{
      width: 100%;
    }
  }
  .btns{
    .btn-yellow,.btn-purple{
      @extend .transition1;
      height: 60px;
    }
    a{
      display: inline-block;
      @extend .transition1;
      text-align: center;
      vertical-align: center;
      padding:0 5px;
      @include font-style($montserrat,700,11.8px,#fff !important);
      text-transform: uppercase;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .btn-yellow{
    background-color: $yellow1;
    &:hover{
      background-color: $yellow2;
    }
  }
  .btn-purple{
    background-color: $purple1;
    &:hover{
      background-color: $purple2;
    }
    img{
      margin-left: 10px;
      margin-top: -4px;
    }
  }
}


.flexfillheader-br {
  background: #6b409c !important;
  margin-top: -1px;
  height: 60px;
  margin-right: -1px;
  margin-left: 32px;
  // padding-right: 52px;
  // padding-left: 53px;
  // .headerAcumula-br {
  //   height: 60px;
  //   color: white !important;
  //   padding-top: 4px !important;
  // }
}


.menu{
  .navbar{
    padding: 0;
  }
  li{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,&.active{
      //background-color: $purple1;
      .select-tab{
        display: block;
      }
      .select-tab-NC{
        display: block;
      }
      //.nav-link{color: #fff;}
    }
  }
  .nav-link{
    @include font-style($montserrat,700,16.5px,$grey1);
    //text-transform: uppercase;
    text-align: center;
    padding: 0 5px;
  }
  .navbar-nav{
    padding-right: 0;
  }
  .dropdown-menu{
    position: absolute;
    margin-top: 0;
    border-radius: .25rem;
    border:0;
    padding: 0;
    box-shadow:0 2px 16px 0 rgba(215,215,215,0.5);
    width: 250px;

  }
  .dropdown-item{
    @extend  .transition1;
    @include font-style($montserrat,700,11.8px,$grey1);
    text-transform: none;
    padding: 24px 17px;
    &:hover{
      background-color: $lightGrey2;
    }
  }
  .item-nav{
    background-color: rgb(209, 209, 209);

    .dropdown-item-nav{
      @extend  .transition1;
      @include font-style($montserrat,700,11.8px,$grey1);
      text-transform: uppercase;
      padding: 24px 17px;
      text-decoration: none;
    }
    &:hover,&.active{
      .dropdown-item-nav{color: #fff;}
    }
  }
  .left-content{
    justify-content: left;
  }
  .icon-after{
    position: relative;
    &:after{
      margin-left: 5px;
      margin-top: -3px;
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      background: transparent url("/assets/img/svg/i-external.svg") center center no-repeat;
    }
  }
}
.menu-mobile{
  display: none;
  position: fixed;

  // overflow:hidden;
  // -webkit-overflow-scrolling:touch;

  background-color: #fff;
  width: 100%;
  max-width: 280px;
  height: 100%;
  right: -100%;
  //top: 250px; //quitar dp comunicado
  top: 0;
  overflow: auto;
  //box-shadow: inset 0 1px 13px rgba(#97999b, .42);
  @extend .transition1;
  z-index: 1000;
  &.active{
    right: 0;
  }
  ul{
    list-style: none;
    padding-left: 0;
    width: 100%;
    padding-top: 5px;
  }
  li{
    width: 100%;
    border-bottom:1px solid rgba(#fff,.1);
    position: relative;
    .nav-link{
      text-align: left;
      @include font-style($montserrat,600,14px,#7D7D7D);
      //opacity: .5;
      //text-transform: capitalize;
      padding: 13px 20px;
      border-bottom: 1px solid #EBEBEB;
      &.active{
        background-color: #D83F3F;
        color: #fff;
      }
    }
    .icon-menu{
      margin-right: 17px;
    }
  }
  li.itemLiResponsiveMenu{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #7d7d7d;
  }

  li.itemLiResponsiveMenu:hover {
    background-color: $darkPurple3;
    border:none;

    // border-left: 1px solid $darkPurple3;
    // border-bottom: 1px solid $darkPurple3;
  }

  li.itemLiResponsiveMenu a.active {
    background-color: $darkPurple3 !important;
    border:none;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #FFFFFF !important;

    #menu3ResponsiveFlecha, #menu4ResponsiveFlecha {
      background-image: url('/assets/img/svg/i-vector-responsive-expande_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu1Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-ripley-puntos_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu2Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-categorias_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu3Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-gift_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu4Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-acumula_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
  }

  li.itemLiResponsiveMenu .nav-link:hover {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #FFFFFF !important;
    #menu1Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-ripley-puntos_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu2Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-categorias_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu3Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-gift_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu3ResponsiveFlecha, #menu4ResponsiveFlecha {
      background-image: url('/assets/img/svg/i-vector-responsive-expande_w0.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
    #menu3ResponsiveFlecha.active, #menu4ResponsiveFlecha.active {
      background-image: url('/assets/img/svg/i-vector-responsive-expande_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }

    #menu4Responsive {
      background-image: url('/assets/img/svg/i-menu-resp-acumula_w.svg');
      background-repeat: no-repeat;
      background-position:center;
    }
  }
  button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }
  .collapsed,.collapse,.collapsing{
    li:first-child{
      padding-top: 0;
    }
    li{
      border: none;
      &:hover{
        background-color: #E9E9E9;
      }
    }
    a{
      padding: 15px 20px 15px 60px;
      display: inline-block;
      @include font-style($montserrat,600,12px,#4c4c4c);
      &:hover{
        color: #8C4799;
        text-decoration: none;
      }
    }
  }
  .dropdown-menu{
    @extend .transition1;
    display: inline-block;
    position: relative;
    background-color: transparent;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 0;
    a{
      padding: 10px 15px;
    }
  }
  nav{
    padding-bottom: 0;
    align-content: space-between;
    height: 100%;
  }
  .footer-menu{
    margin-bottom: 0;
    li{
      background-color: #fff;
      &:hover{
        background-color: #E9E9E9;
      }
      a{
        // border-top: 1px solid #EBEBEB;
        // border-bottom: 0;
        color: #575757;
      }
    }
    a{
      opacity: 1;
      &:hover{
        color: #8C4799;
        text-decoration: none;
      }
    }
  }
}
#menu_desplegable, #menu_desplegable2{
  background-color: #fff !important;
}
.btn_r_SubMenuRPGO{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color:$grey1 !important;
}
.btn_r_SubMenuRPGO:hover{
  background-color: #E9E9E9 !important;
  color: $purple4 !important;
}
#menu1Responsive {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
  background-image: url("/assets/img/svg/i-menu-resp-ripley-puntos.svg");
  background-repeat:no-repeat;
  background-position:center;
}
#menu2Responsive {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
  background-image: url("/assets/img/svg/i-menu-resp-categorias.svg");
  background-repeat:no-repeat;
  background-position:center;
}
#menu3Responsive {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
  background-image: url("/assets/img/svg/i-menu-resp-gift.svg");
  background-repeat:no-repeat;
  background-position:center;
}

#menu3ResponsiveFlecha, #menu4ResponsiveFlecha {
  width: 20px;
  height: 20px;
  float: right;

  background-image: url("/assets/img/svg/i-vector-responsive-expande.svg");
  background-repeat:no-repeat;
  background-position:center;
}

#menu4Responsive {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
  background-image: url("/assets/img/svg/i-menu-resp-acumula.svg");
  background-repeat:no-repeat;
  background-position:center;
}




// #menu1Responsive:hover {
//   background-image: url('../img/svg/i-menu-resp-ripley-puntos_w.svg');
//   background-repeat: no-repeat;
//   background-position:center;
// }

.btns-mobile{
  display: none ;
  .btn-purple{
    @extend .transition1;
    height: 60px;
    width: 60px;
    cursor: pointer;
    img{
      margin: 0;
    }
  }
  .btnMenu{
    height: 60px;
    width: 60px;
    cursor: pointer;
    background-color: $darkPurple1;

    .line{
      background-color: #fff;
      width: 20px;
      height: 2px;
      position: relative;
      border-radius: 5px;
      &:after,&:before{
        border-radius: 5px;
        content: '';
        position: absolute;
        height: 2px;
        width: 20px;
        background: #fff;
      }
      &:before{
        top: 7px;
      }
      &:after{
        bottom: 7px;
      }
    }
  }
}
.box-ripleyApp{
  @extend .transition1;
  padding-top: 25px;
  box-shadow: 0 2px 20px 0 rgba(202,202,202,0.5);
  position: fixed;
  height: 30vh;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  &.out{
    transform: translateY(100%);
  }
  #box-ripleyApp_close{
    position: absolute;
    top: 5px;
    right: 15px;
    height: 25px;
    width: 25px;
    background: transparent url("/assets/img/svg/i-close.svg") center center no-repeat;
    background-size: 40% auto;
    opacity: .3;
    z-index: 10000;
  }
  .box-ripleyApp_content{
    width: 100%;
    padding: 0 10px;
    img{
      padding:0 2px;
      max-width: 25vw;
    }
    p{
      padding:0 2px;
    }
  }
  .box-ripleyApp_link{
    padding: 15px 0;
  }
  .btnRed{
    padding: .5rem 2.5rem;
  }
}

@include respond-to(1100px){
  .sites{
    a{
      font-size: 10px;
    }
  }
  .menu{
    .dropdown-item,.nav-link{
      font-size: 16px;
    }
  }
  .menu-br{
    .btns a{
      font-size: 10px;

    }
    .logo-br{
      max-width: 150px;
    }
  }
}
@include respond-to(1024px){
  .menu-br{
    justify-content: space-between;
  }
  .sites-nav,.btns,.menu{
    display: none;
  }
  .menu-mobile{
    display: inline-block;
  }
  .btns-mobile{
    display: flex;
  }
}

//fin header styles
@media (min-width: 360px) {
  .ripley-header-flotante{
    display: none;
  }
  .menu-br-flotante{
    display: none;
  }
  .ripley-header{
    display: block;
    //top: 186px !important; // quitar dp de comunicado
  }
  .menu-br{
    display: block;
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .ripley-header-flotante{
    display: none;
  }
  .menu-br-flotante{
    display: none;
  }
  .ripley-header{
    display: block;
    //top: 181px !important; // quitar dp de comunicado
  }
  .menu-br{
    display: block;
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 1024px) {
  .ripley-header-flotante{
    display: inline-block;
  }
  .menu-br-flotante{
    display: inline-block;
  }
  .ripley-header{
    display: inline-block;
  }
  .menu-br{
    display: inline-block;
  }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .ripley-header-flotante{
    display: inline-block;
  }
  .menu-br-flotante{
    display: inline-block;
  }
  .ripley-header{
    display: inline-block;
  }
  .menu-br{
    display: inline-block;
  }
 }
 .select-tab{
  background-color:  #462A66;
  display: none;
  border: 1.5px solid #462A66; max-width: 95%;border-radius: 20%;
}

.select-tab-NC{
  background-color:  #FAFAFA;
  display: none;
  border: 1.5px solid #FAFAFA;; max-width: 95%;border-radius: 20%;
}

.select-tab-mark{
  background-color:  #462A66;
  display: none;
  border: 1.5px solid #462A66; max-width: 95%;border-radius: 20%;
}

.select-tab-mark-NC{
  background-color:  #FAFAFA;
  display: none;
  border: 1.5px solid #FAFAFA; max-width: 95%;border-radius: 20%;
}
