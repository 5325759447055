.cont-footer-new{
  background:#191919;
  padding: 20px 0px 8px 0;
  ul{
    padding-left: 0;
  }
  ul,li{
    list-style: none;
  }
  .carousel-control{
    &-prev, &-next{
      top: 55%;
      opacity: 1;
    }
    &-prev{
      left: -15px !important;
      &:after{
        left: 0 !important;
      }
    }
    &-next{
      right: -15px;
      &:after{
        right: 0;
      }
    }
  }
}
.box-footer{
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 0;
  padding-right: 5px;
  padding-left: 5px;
}
.footer-line{
  background-color: $grey1;
}
.box-footer__contenido{
  width: 100%;
  padding: 0 15px;
  min-height: 170px;
  img{
    max-height: 55px;
    margin: 10px 0;
  }
  .link{
    margin: 20px 0;
  }
}
//pustos go
.footerMsg {
  border-top: 1px solid $lightGrey2;
  padding: 20px 0;
  text-align: center;
  p{
    padding: 0 15px;
  }
  @include respond-to(576px){
    a{
      margin: 15px 0 !important;
      width: 100%;
      max-width: 100%;
    }
  }
}
.cont-footer{
  .arrow-down{
    &:after{
      bottom: 45%;
    }
  }
  background-color: rgba($lightGrey2, .5);
  padding-top: 30px;
  ul,li{
    list-style: none;
  }
  ul{
    padding-left: 0;
  }
  li{
    margin-bottom: 5px;
  }
  .title{
    text-transform: uppercase;
    @include font-style($montserrat,600,11.5px,$darkPurple1);
  }
  a{
    @extend .transition1;
    @include font-style($montserrat,400,12.5px,$grey2);
    &:hover{
      text-decoration: none;
      color: $grey1;
    }
  }
  img{
    height: 25px;
    // margin-top: 10px;
    width: auto;
    padding-right: 15px;
  }
  button.title{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    text-align: left;
    padding: 15px 10px 10px 10px;
    color: $grey1;
    font-weight: 700;
    border-bottom: 1px solid rgba($grey3,.3);
  }

  //PUNTOS GO
  .collapse,.collapsed,.collapsing{
    padding: 5px 10px;
  }
  .btnRed{
    color: #191919;
    font-weight: 600;
    margin: 15px 0;
    width: 100%;
    max-width: 200px;
    img{
      width: 100%;
      margin-top: 0;
      max-width: 25px;
    }
  }
  @include respond-to(768px){
    padding-top: 0;
    img{
      padding-right: 10px;
      margin-top: 5px;
    }
    .grey{
      opacity: .5;
    }
    span{
      padding: 25px 0 0 0;
      a{
        margin: 10px 10px 35px 10px;
      }
      p{
        text-transform: capitalize !important;
        opacity: 1 !important;
      }
    }
  }
}
.cont-footer_rrss{
  margin: 25px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba($grey2,.3);
  a{
    padding-left: 10px;
    img{
      width: auto;
      height: 20px;
    }
  }
  @include respond-to(768px){
    border-bottom: none;
    margin: 0;
  }
}
.cont-footer__legal{
  height: 35px;
  background: rgba($grey3,.2);
  p{
    text-align: center;
    padding: 10px 15px;
    @include font-style($montserrat,400,12.5px,$grey1);
    margin-bottom: 0;
    a{
      @include font-style($montserrat,700,12.5px,$grey1);
    }
  }
}
.barra-coloresBR{
  margin-top: 25px;
  display: inline-block;
  height: 8px;
  width: 100%;
  overflow: hidden;
  .line-red,.line-yellow,.line-purple{
    display: inline-block;
    height: 8px;
    float: left;
  }
  .line-red{
    background: $red1;
    width: 50%;
  }
  .line-yellow{
    background: $yellow1;
    width: 15%;
  }
  .line-purple{
    background: $purple1;
    width: 35%;
  }
}
.toggle-mobile{
  position: absolute;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  &.active{
    display: inline-block;
    cursor: pointer;
  }
}

.fa-reclamaciones{
  height: 60px !important;
  @include respond-to(768px){
    height: 55px !important;
  }
}

.align-center-xs{
  @include respond-to(768px){
    text-align: center !important;
  }
} 
